import createAuth0Client from '@auth0/auth0-spa-js'

// Default uri for callback from auth0
export const DEFAULT_REDIRECT_URI = process.env.VUE_APP_AUTH0_REDIRECT_URI || (`${window.location.origin}/login-callback`)

let auth0Client

// Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance
export const getClient = async ({
  redirectUri = DEFAULT_REDIRECT_URI,
  ...options
}) => {
  if (!auth0Client) {
    // Create a new instance of the SDK client using members of the given options object
    auth0Client = await createAuth0Client({
      ...options,
      client_id: options.clientId,
      redirect_uri: redirectUri,
    })
  }

  return auth0Client
}

// Check if current location is auth0 callback
export const isCallback = () => window.location.search.includes('code=')
  && window.location.search.includes('state=')

export default getClient
