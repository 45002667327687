<template>
  <b-overlay
    :show="promoCodeStore.pending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-card-code
      title="Discount code"
      card-header-class="pb-1"
    >
      <div
        v-if="promoCodeStore.applied"
      >
        Code applied successfully!
      </div>

      <div
        v-else-if="promoCodeStore.response === null || promoCodeStore.response.promoCode != promoCodeInput"
      >
        <b-form
          class="mt-0 notifications"
          @submit.prevent="useCode"
        >
          <b-row>
            <b-col cols="4">
              <b-form-input
                v-model="promoCodeInput"
              />
            </b-col>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="promoCodeStore.pending"
              >
                Use code
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </div>
      <div
        v-else
      >
        <b-row>
          <b-col cols="4">
            <p>
              Code "{{ promoCodeStore.response.promoCode }}"
              <span v-if="promoCodeStore.response.amountOff !== null">-${{ promoCodeStore.response.amountOff }}</span>
              <span v-if="promoCodeStore.response.percentOff !== null">-{{ promoCodeStore.response.percentOff }}%</span>
            </p>
          </b-col>
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="promoCodeStore.pending"
              @click="applyCode"
            >
              Apply
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-code>
  </b-overlay>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Vuelayers, {
  TileLayer,
  OsmSource,
  Overlay,
} from 'vuelayers'
import BCardCode from '@core/components/b-card-code'
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vuelayers(Vue)

export default {
  components: {
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    TileLayer,
    // eslint-disable-next-line vue/no-unused-components
    OsmSource,
    // eslint-disable-next-line vue/no-unused-components
    Overlay,
    // validations -->
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      promoCodeInput: null,
    }
  },
  computed: {
    ...mapState('SubscriptionStoreModule', {
      promoCodeStore: state => state.promoCode,
    }),
  },
  watch: {
    currentTab() {
      this.$refs.vlMap.mount()
    },
  },
  methods: {
    ...mapActions('SubscriptionStoreModule', ['useStylistPromoCode', 'applyStylistPromoCode']),

    useCode() {
      this.useStylistPromoCode(this.promoCodeInput)
        .catch(() => this.fail('Invalid code'))
    },

    applyCode() {
      this.applyStylistPromoCode()
        .catch(() => this.fail('Unable to activate given code'))
    },

    fail(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
  height: 100% !important;
}
.overlay-content {
  border-radius: 100%;
  border: 2px solid red;
  background-color: white;
  min-width: 12px;
  min-height: 12px;
  display: block;
  transform: translateX(-6px) translateY(-6px);
}
.card .card-header p{
  padding-bottom: 1.5rem;
}
form.notifications {
  .row {
    padding-bottom: 1.5rem;
  }
  h4 {
    margin-bottom: 1.0rem;
  }
}
</style>
