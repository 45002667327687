import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    stylistData: {},
    loaded: false,
    loading: false,
    saving: false,
  },
  getters: {
    stylistId(state) {
      return state.stylistData.stylistId || api.$auth.stylistId
    },
    uri(state, getters) {
      return `/api/stylists/${getters.stylistId}`
    },
  },
  mutations: {
    setLoading(state) {
      state.loading = true
    },

    setSaving(state) {
      state.saving = true
    },

    unsetSaving(state) {
      state.saving = false
    },

    setStylistData(state, data) {
      state.stylistData = data
      state.loading = false
      state.saving = false
    },
  },

  actions: {
    async fetchStylistData({ commit, getters }) {
      await commit('setLoading')

      const response = await api.get(getters.uri)

      await commit('setStylistData', response.data)
    },

    async saveStylistData({ commit, getters }, data) {
      await commit('setSaving')

      const response = await api.patch(getters.uri, data)

      await commit('setStylistData', response.data)
    },
  },
}
