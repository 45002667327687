import { ERROR_403, ERROR_404, AUTH_LOGOUT } from '@/router/routes/routes-names'
import { COMMON_ERROR_403, COMMON_ERROR_404, AUTH_LOGOUT_PATH } from '@/router/routes/routes-path'

export default [
  // *===============================================---*
  // *--------- Error 404-------------------------------*
  // *===============================================---*
  {
    path: COMMON_ERROR_404,
    name: ERROR_404,
    component: () => import('@/components/error/Error404.vue'),
    meta: {
      requireLogin: false,
      layout: 'full',
    },
  },
  // *===============================================---*
  // *--------- Error 403-------------------------------*
  // *===============================================---*
  {
    path: COMMON_ERROR_403,
    name: ERROR_403,
    component: () => import('@/components/error/Error403.vue'),
    meta: {
      requireLogin: false,
      layout: 'full',
    },
  },
  // *===============================================---*
  // *--------- Logout ---------------------------------*
  // *===============================================---*
  {
    path: AUTH_LOGOUT_PATH,
    name: AUTH_LOGOUT,
    component: () => import('@/components/auth/Logout.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [],
    },
  },
]
