import {
  CUSTOMER_LIST,
  CUSTOMER_EDIT,
  STYLIST_SETTINGS,
  SERVICE_LIST,
  SERVICE_EDIT,
  CALENDAR,
  CUSTOMER,
  STYLIST_PAGE,
  BOOKING,
  BOOKING_FIRST_STEP_PAGE,
  BOOKING_SECOND_STEP_PAGE,
  BOOKINGS_LIST,
  SERVICE_REQUEST_CONSULTATION,
  TERMS_OF_SERVICE,
  BOOKING_SUCCESS_PAGE,
  APPOINTMENT,
  APPOINTMENT_LIST,
  APPOINTMENT_SHOW,
  REVIEW_LIST,
  REVIEW,
  APPOINTMENT_REVIEW,
  DASHBOARD,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_RETURN,
} from '@/router/routes/routes-names'
import StylistServiceList from '@/components/stylist/stylist-service/StylistServiceList.vue'
import StylistSetting from '@/components/stylist/stylist-setting/StylistSetting.vue'
import {
  APPS_CALENDAR,
  AUTH_CUSTOMER_CALLBACK_PATH,
  AUTH_LOGIN_PATH,
  TERMS_OF_SERVICE_PATH,
  STYLIST_SETTINGS_PATH,
  SERVICES_BOOKING_PATH,
  SERVICES_BOOKINGS_PATH,
  SERVICES_REQUEST_CONSULTATION_PATH,
  APPOINTMENT_REVIEW_PATH,
  APPS_DASHBOARD_PATH,
  SUBSCRIPTION_PLANS_PATH,
  SUBSCRIPTION_RETURN_PATH,
} from '@/router/routes/routes-path'
import { ROLE_CUSTOMER, ROLE_STYLIST } from '@/auth/userRolesDictionary'

export default [
  // *===============================================---*
  // *--------- Default path ---- ----------------------*
  // *===============================================---*
  { path: '/', redirect: { name: STYLIST_PAGE } },

  // *===============================================---*
  // *--------- Profile page ---- ----------------------*
  // *===============================================---*
  {
    path: '/',
    name: STYLIST_PAGE,
    component: () => import('@/components/stylist/stylist-page/StylistPage.vue'),
    meta: {
      layout: 'full',
      requireLogin: false,
    },
  },

  // *===============================================---*
  // *--------- Stylist review ---- ----------------------*
  // *===============================================---*
  {
    path: APPOINTMENT_REVIEW_PATH,
    name: APPOINTMENT_REVIEW,
    component: () => import('@/components/stylist/stylist-page-review/StylistPageReview.vue'),
    meta: {
      layout: 'full',
      requireLogin: false,
    },
  },
  // *===============================================---*
  // *--------- Booking page ---- ----------------------*
  // *===============================================---*
  {
    path: '/services/book',
    name: BOOKING_FIRST_STEP_PAGE,
    component: () => import('@/components/booking/booking-service/BookingServiceFirstStepPage.vue'),
    meta: {
      layout: 'full',
      requireLogin: false,
    },
  },
  {
    path: '/services/book/confirm',
    name: BOOKING_SECOND_STEP_PAGE,
    component: () => import('@/components/booking/booking-service/BookingServiceSecondStepPage.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_CUSTOMER],
    },
  },
  {
    path: '/services/book/success',
    name: BOOKING_SUCCESS_PAGE,
    component: () => import('@/components/booking/booking-service/BookingServiceSuccess.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_CUSTOMER],
    },
  },
  {
    path: SERVICES_BOOKINGS_PATH,
    name: BOOKINGS_LIST,
    component: () => import('@/components/booking/booking-service/BookingsList.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_CUSTOMER],
    },
  },
  {
    path: SERVICES_REQUEST_CONSULTATION_PATH,
    name: SERVICE_REQUEST_CONSULTATION,
    component: () => import('@/components/booking/booking-service/BookingServiceRequestConsultation.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_CUSTOMER],
    },
  },
  {
    path: `${SERVICES_BOOKING_PATH}/:id`,
    name: BOOKING,
    component: () => import('@/components/booking/booking-service/Booking.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_CUSTOMER],
    },
  },

  // *===============================================---*
  // *--------- Terms Of Services ---- -----------------*
  // *===============================================---*
  {
    path: TERMS_OF_SERVICE_PATH,
    name: TERMS_OF_SERVICE,
    component: () => import('@/components/sites/Tos'),
    meta: {
      layout: 'full',
      requireLogin: false,
    },
  },

  // *===============================================---*
  // *--------- Service list ---- ----------------------*
  // *===============================================---*
  {
    path: '/apps/services/list',
    name: SERVICE_LIST,
    component: StylistServiceList,
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
    },
  },
  {
    path: '/apps/services/:id/edit',
    name: SERVICE_EDIT,
    component: () => import('@/components/stylist/stylist-service/StylistServiceEdit.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
    },
  },

  // *===============================================---*
  // *--------- Stylist settings------------------------*
  // *===============================================---*
  {
    path: STYLIST_SETTINGS_PATH,
    name: STYLIST_SETTINGS,
    component: StylistSetting,
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
    },
  },
  // *===============================================---*
  // *--------- Customer -------------------------------*
  // *===============================================---*
  {
    path: '/apps/customers',
    name: CUSTOMER,
    redirect: '/apps/customers/list',
  },
  {
    path: '/apps/customers/list',
    name: CUSTOMER_LIST,
    component: () => import('@/components/stylist/stylist-customers/StylistCustomerList.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      navActiveLink: CUSTOMER,
    },
  },
  {
    path: '/apps/customers/:id/edit',
    name: CUSTOMER_EDIT,
    component: () => import('@/components/stylist/stylist-customers/StylistCustomerEdit.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      navActiveLink: CUSTOMER,
    },
  },
  // *===============================================---*
  // *--------- Appointment ----------------------------*
  // *===============================================---*
  {
    path: '/apps/appointments',
    name: APPOINTMENT,
    redirect: '/apps/appointments/list',
  },
  {
    path: '/apps/appointments/list',
    name: APPOINTMENT_LIST,
    component: () => import('@/components/stylist/stylist-appointment/StylistAppointmentList.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      navActiveLink: APPOINTMENT,
    },
  },
  {
    path: '/apps/appointment/:id/show',
    name: APPOINTMENT_SHOW,
    component: () => import('@/components/stylist/stylist-appointment/StylistAppointmentShow.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      navActiveLink: APPOINTMENT,
    },
  },
  // *===============================================---*
  // *--------- Reviews --------------------------------*
  // *===============================================---*
  {
    path: '/apps/reviews',
    name: REVIEW,
    redirect: '/apps/reviews/list',
  },
  {
    path: '/apps/reviews/list',
    name: REVIEW_LIST,
    component: () => import('@/components/stylist/stylist-review/StylistReviewList.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      navActiveLink: REVIEW,
    },
  },
  // *===============================================---*
  // *--------- AUTH CUSTOMER --------------------------*
  // *===============================================---*
  {
    path: AUTH_CUSTOMER_CALLBACK_PATH,
    name: 'auth-customer-callback',
    component: () => import('@/components/auth/auth-customer/AuthCustomerCallbackSubdomain.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  // *===============================================---*
  // *--------- Stylist dashboard ----------------------*
  // *===============================================---*
  {
    path: APPS_DASHBOARD_PATH,
    name: DASHBOARD,
    component: () => import('@/components/stylist/stylist-dashboard/StylistDashboard'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      requireSubscription: true,
    },
  },
  // *===============================================---*
  // *--------- Calendar -------------------------------*
  // *===============================================---*
  {
    path: APPS_CALENDAR,
    name: CALENDAR,
    component: () => import('@/components/stylist/stylist-calendar/StylistCalendar.vue'),
    meta: {
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      requireSubscription: true,
    },
  },
  {
    path: AUTH_LOGIN_PATH,
    name: 'apps-login',
    redirect: { name: CALENDAR },
  },
  // *===============================================---*
  // *--------- SaaS -----------------------------------*
  // *===============================================---*
  {
    path: SUBSCRIPTION_PLANS_PATH,
    name: SUBSCRIPTION_PLANS,
    component: () => import('@/components/subscription/Plans.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
    },
  },
  {
    path: `${SUBSCRIPTION_RETURN_PATH}/:success`,
    name: SUBSCRIPTION_RETURN,
    component: () => import('@/components/subscription/Return.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
    },
  },
]
