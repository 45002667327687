export const STYLIST_SETTINGS = 'stylist-setting'
export const CUSTOMER = 'customer'
export const CUSTOMER_LIST = 'customer-list'
export const CUSTOMER_EDIT = 'customer-edit'
export const SERVICE_LIST = 'service-list'
export const SERVICE_EDIT = 'service-edit'
export const AUTH_LOGOUT = 'auth-logout'
export const CALENDAR = 'calendar'
export const STYLIST_PAGE = 'stylist-page'
export const BOOKING = 'booking'
export const BOOKING_FIRST_STEP_PAGE = 'booking-first-step-page'
export const BOOKING_SECOND_STEP_PAGE = 'booking-second-step-page'
export const BOOKING_SUCCESS_PAGE = 'booking-page-success'
export const BOOKINGS_LIST = 'bookings-list'
export const SERVICE_REQUEST_CONSULTATION = 'service-request-consultation'
export const ERROR_404 = 'error-404'
export const ERROR_403 = 'error-403'
export const TERMS_OF_SERVICE = 'tos'
export const APPOINTMENT = 'appointment'
export const APPOINTMENT_LIST = 'appointment-list'
export const APPOINTMENT_SHOW = 'appointment-show'
export const REVIEW = 'review'
export const REVIEW_LIST = 'review-list'
export const APPOINTMENT_REVIEW = 'appointment-review'
export const DASHBOARD = 'dashboard'
export const SUBSCRIPTION_PLANS = 'subscription-plans'
export const SUBSCRIPTION_RETURN = 'subscription-return'

export default {
  STYLIST_SETTINGS,
  CUSTOMER,
  CUSTOMER_LIST,
  CUSTOMER_EDIT,
  CALENDAR,
  AUTH_LOGOUT,
  STYLIST_PAGE,
  ERROR_404,
  ERROR_403,
  BOOKING,
  BOOKING_FIRST_STEP_PAGE,
  BOOKING_SECOND_STEP_PAGE,
  BOOKING_SUCCESS_PAGE,
  SERVICE_REQUEST_CONSULTATION,
  BOOKINGS_LIST,
  TERMS_OF_SERVICE,
  APPOINTMENT,
  APPOINTMENT_LIST,
  APPOINTMENT_SHOW,
  REVIEW,
  REVIEW_LIST,
  APPOINTMENT_REVIEW,
  DASHBOARD,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_RETURN,
}
