import {
  AUTH_CALLBACK_PATH,
  AUTH_CUSTOMER_CALLBACK_PATH,
  AUTH_CUSTOMER_LOGIN_PATH,
  AUTH_LOGIN_PATH,
  AUTH_REGISTER_PATH,
} from '@/router/routes/routes-path'
import { ROLE_STYLIST } from '@/auth/userRolesDictionary'

export default [
  {
    path: '/',
    name: 'landing-page',
    beforeEnter: () => {
      window.location.pathname = AUTH_LOGIN_PATH
    },
  },
  {
    path: AUTH_LOGIN_PATH,
    name: 'auth-login',
    component: () => import('@/components/auth/auth-stylist/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: AUTH_CALLBACK_PATH,
    name: 'auth-callback',
    component: () => import('@/components/auth/auth-stylist/Callback.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: AUTH_REGISTER_PATH,
    name: 'auth-register',
    component: () => import('@/components/auth/auth-stylist/Register.vue'),
    meta: {
      layout: 'full',
      requireLogin: true,
      requireRoles: [ROLE_STYLIST],
      allowUnregistered: true,
    },
  },
  {
    path: AUTH_CUSTOMER_LOGIN_PATH,
    name: 'auth-customer-login',
    component: () => import('@/components/auth/auth-customer/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: AUTH_CUSTOMER_CALLBACK_PATH,
    name: 'auth-customer-callback',
    component: () => import('@/components/auth/auth-customer/AuthCustomerCallbackSubdomain.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
]
