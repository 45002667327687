<template>
  <b-overlay
    :show="stylistPolicyStore.isPending || stripeAccountStore.isPending"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-alert
      variant="danger"
      :show="!validConnectedStripe() && !stylistPolicyStore.isPending && !stripeAccountStore.isPending"
    >
      <h4 class="alert-heading">
        No-show protection is not available for accounts without payments acceptance configured. Please <span
          class="underline cursor-pointer"
          @click="setActiveTab(tabs.ACCEPTING_PAYMENTS)"
        >click here to configure payments acceptance.</span>
      </h4>
    </b-alert>
    <b-card-code
      title="No-show Protection"
      :class="{'color-gray': !validConnectedStripe()}"
    >
      <div>
        <span>No-show protection configuration allows you to define fees your customers will be charged when they do not show up
          for or cancel an appointment.</span><br>
        <span>Please be advised, that enabling no-show and/or cancellation policies will require your customers to provide their credit-card information during booking process.</span>
      </div>
      <div>
        <b-row>
          <div>
            <b-col
              cols="12"
              class="mt-1 mb-1"
            >
              <b-form-checkbox
                v-model="noShowPolicyCheckbox"
                :disabled="!validConnectedStripe()"
                name="check-button"
                switch
                inline
              >
                <span
                  class="font-medium-3 font-weight-bold"
                >
                  No-show policy
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="12">
              <span>Define what percentage of original service fee your customers will be charged, in case they do not show up</span>
            </b-col>
            <b-col cols="12">
              <div class="d-flex d-inline">
                <b-form-radio
                  v-model="noShowPolicy"
                  name="no-show-policy"
                  class="m-1"
                  :disabled="!noShowPolicyCheckbox || !validConnectedStripe()"
                  value="50"
                >
                  50%
                </b-form-radio>
                <b-form-radio
                  v-model="noShowPolicy"
                  name="no-show-policy"
                  class="m-1"
                  :disabled="!noShowPolicyCheckbox || !validConnectedStripe()"
                  value="100"
                >
                  100%
                </b-form-radio>
              </div>
            </b-col>
          </div>
        </b-row>
      </div>
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mt-1 mb-1"
          >
            <b-form-checkbox
              v-model="cancellationPolicyCheckbox"
              :disabled="!validConnectedStripe()"
              name="check-button"
              switch
              inline
            >
              <span
                class="font-medium-3 font-weight-bold"
              >
                Cancellation policy
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <span>Define what percentage of original service fee your customers will be charged, in case they do cancel their appointment</span>
          </b-col>
          <b-col
            cols="12"
            class="d-flex align-items-center"
          >
            <div class="d-flex d-inline">
              <b-form-radio
                v-model="cancellationPolicy"
                name="cancellation-policy"
                class="m-1"
                :disabled="!cancellationPolicyCheckbox || !validConnectedStripe()"
                value="25"
              >
                25%
              </b-form-radio>
              <b-form-radio
                v-model="cancellationPolicy"
                name="cancellation-policy"
                class="m-1"
                :disabled="!cancellationPolicyCheckbox || !validConnectedStripe()"
                value="50"
              >
                50%
              </b-form-radio>
              <b-form-radio
                v-model="cancellationPolicy"
                name="cancellation-policy"
                class="m-1"
                :disabled="!cancellationPolicyCheckbox || !validConnectedStripe()"
                value="75"
              >
                75%
              </b-form-radio>
              <b-form-radio
                v-model="cancellationPolicy"
                name="cancellation-policy"
                class="m-1"
                :disabled="!cancellationPolicyCheckbox || !validConnectedStripe()"
                value="100"
              >
                100%
              </b-form-radio>
            </div>
          </b-col>
          <b-col cols="12">
            <span>Cancellation fee will only be charged if customer cancels their appointment within this many hours before appointment</span>
            <b-input-group
              class="width-300 mt-1"
              append="Hrs"
            >
              <b-form-input
                id="hours-before-appointment"
                v-model.number="hoursBeforeAppointment"
                :state="!hoursBeforeAppointment ? false : null"
                :disabled="!cancellationPolicyCheckbox || !validConnectedStripe()"
                type="number"
              />
            </b-input-group>
            <small
              v-if="!validHoursBeforeAppointment()"
              class="text-danger"
            >Field hours before appointment must be set min 1 hour and max 96 hours!</small>
          </b-col>
        </b-row>
      </div>
      <b-col
        cols="12"
        class="pl-0 mt-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          type="submit"
          :disabled="saveStylistPolicyStore.isPending || (cancellationPolicyCheckbox && !validHoursBeforeAppointment()) || !validConnectedStripe()"
          @click="saveStylistPolicyConfig"
        >
          Save changes
        </b-button>
        <div
          v-if="saveStylistPolicyStore.isPending"
          class="mb-1 d-inline"
        >
          <b-spinner small />
        </div>
      </b-col>
    </b-card-code>
  </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BAlert,
  BFormCheckbox,
  BCol,
  BOverlay,
  BButton,
  BFormRadio,
  BInputGroup,
  BFormInput,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  CANCELLATION_POLICY,
  HOURS_BEFORE_APPOINTMENT_POLICY,
  NO_SHOW_POLICY,
} from '@/dictionaries/stylistPolicyConfigDictionary'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import StylistSettingTabsEnum from '@/dictionaries/stylistSettingTabsEnum'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BCardCode,
    BFormCheckbox,
    BOverlay,
    BCol,
    BButton,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BRow,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      noShowPolicy: 50,
      noShowPolicyCheckbox: false,
      cancellationPolicy: 25,
      cancellationPolicyCheckbox: false,
      hoursBeforeAppointment: 1,
      policyConfig: null,
      stripeAccount: {},
      tabs: {
        ...StylistSettingTabsEnum,
      },
    }
  },
  computed: {
    ...mapState('StylistSettingStoreModule', {
      stylistPolicyStore: state => state.stylistPolicy,
      saveStylistPolicyStore: state => state.saveStylistPolicy,
      stripeAccountStore: state => state.stripeAccount,
    }),
    ...mapState('AccountData', {
      stylistData: state => state.stylistData,
    }),
  },
  watch: {
    stripeAccountStore: {
      deep: true,
      handler(newStripeAccount) {
        if (newStripeAccount.response) {
          this.stripeAccount = newStripeAccount.response
        }
      },
    },
    stylistPolicyStore: {
      deep: true,
      handler(newStylistPolicy) {
        if (newStylistPolicy.response) {
          newStylistPolicy.response.forEach(policy => {
            this.mapStylistPolicy(policy)
          })
        }
      },
    },
    saveStylistPolicyStore: {
      deep: true,
      handler(newSaveStylistPolicy) {
        if (newSaveStylistPolicy.response) {
          this.success('Settings has been save!')
        } else if (newSaveStylistPolicy.error) {
          this.fail('Settings has not been save!')
        }
      },
    },
  },
  mounted() {
    this.fetchStylistPolicy()
    this.fetchStripeAccount()
  },
  methods: {
    ...mapActions('StylistSettingStoreModule', [
      'saveStylistPolicy',
      'fetchStylistPolicy',
      'fetchStripeAccount',
      'setActiveTab',
    ]),
    saveStylistPolicyConfig() {
      this.prepareData()
      this.saveStylistPolicy(this.policyConfig)
    },
    prepareData() {
      this.policyConfig = []
      if (this.noShowPolicyCheckbox) {
        this.policyConfig.push({
          stylistConfig: NO_SHOW_POLICY,
          value: this.noShowPolicy,
        })
      }
      if (this.cancellationPolicyCheckbox) {
        this.policyConfig.push({
          stylistConfig: CANCELLATION_POLICY,
          value: this.cancellationPolicy,
        })
        this.policyConfig.push({
          stylistConfig: HOURS_BEFORE_APPOINTMENT_POLICY,
          value: moment.duration(+this.hoursBeforeAppointment, 'hours').asMinutes(),
        })
      }
    },
    mapStylistPolicy(policy) {
      switch (policy.stylistPolicyConfigDictionary) {
        case NO_SHOW_POLICY:
          this.noShowPolicy = policy.value
          this.noShowPolicyCheckbox = true
          break
        case CANCELLATION_POLICY:
          this.cancellationPolicy = policy.value
          this.cancellationPolicyCheckbox = true
          break
        case HOURS_BEFORE_APPOINTMENT_POLICY:
          this.hoursBeforeAppointment = moment.duration(+policy.value, 'minutes').asHours()
          this.cancellationPolicyCheckbox = true
          break
        default:
          this.fail('Problem with fetch stylist policy settings!')
      }
    },
    success(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    fail(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    validHoursBeforeAppointment() {
      return this.hoursBeforeAppointment && this.hoursBeforeAppointment >= 1 && this.hoursBeforeAppointment <= 96
    },
    validConnectedStripe() {
      return this.stylistData.stripeId && this.stripeAccount.charges_enabled && this.stylistData.stripeAccountEnabled
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
  height: 100% !important;
}
.overlay-content {
  border-radius: 100%;
  border: 2px solid red;
  background-color: white;
  min-width: 12px;
  min-height: 12px;
  display: block;
  transform: translateX(-6px) translateY(-6px);
}
.width-300 {
  max-width: 300px;
}

.underline {
  text-decoration: underline;
}

.color-gray {
  color: #b8c2cc !important;
}
</style>
