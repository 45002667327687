import { apiInstance as api } from '@/api/plugin'
import { REVIEWS_API_ENDPOINT } from '@/api/apiUrl'

export default {
  namespaced: true,
  state: {
    reviews: {
      response: [],
      isPending: false,
      totalItems: 0,
      error: null,
    },
    review: {
      response: null,
      isPending: false,
      error: null,
    },
    reviewsPending: {
      isPending: false,
      totalItems: 0,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_REVIEWS_RECEIVED(state, response) {
      state.reviews.isPending = false
      state.reviews.response = response.data['hydra:member']
      state.reviews.totalItems = response.data['hydra:totalItems']
      state.reviews.error = null
    },
    FETCH_REVIEWS_PENDING(state) {
      state.reviews.isPending = true
      state.reviews.response = []
      state.reviews.error = null
    },
    FETCH_REVIEWS_ERROR(state, error) {
      state.reviews.isPending = false
      state.reviews.response = []
      state.reviews.error = error
    },
    FETCH_REVIEWS_PENDING_RECEIVED(state, response) {
      state.reviewsPending.isPending = false
      state.reviewsPending.totalItems = response.data['hydra:totalItems']
      state.reviewsPending.error = null
    },
    FETCH_REVIEWS_PENDING_PENDING(state) {
      state.reviewsPending.isPending = true
      state.reviewsPending.totalItems = 0
      state.reviewsPending.error = null
    },
    FETCH_REVIEWS_PENDING_ERROR(state, error) {
      state.reviewsPending.isPending = false
      state.reviewsPending.totalItems = 0
      state.reviewsPending.error = error
    },
    DECREASE_REVIEWS_PENDING_AMOUNT(state) {
      if (state.reviewsPending.totalItems > 0) {
        state.reviewsPending.totalItems -= 1
      }
    },
    FETCH_REVIEW_RECEIVED(state, response) {
      state.review.isPending = false
      state.review.response = response.data
      state.review.error = null
    },
    FETCH_REVIEW_PENDING(state) {
      state.review.isPending = true
      state.review.response = null
      state.review.error = null
    },
    FETCH_REVIEW_ERROR(state, error) {
      state.review.isPending = false
      state.review.response = null
      state.review.error = error
    },
    UPDATE_REVIEW_PENDING(state) {
      state.review.isPending = true
      state.review.response = null
      state.review.error = null
    },
    UPDATE_REVIEW_ERROR(state, error) {
      state.review.isPending = false
      state.review.response = null
      state.review.error = error
    },
  },
  actions: {
    fetchReviews({ commit }, queryParams) {
      commit('FETCH_REVIEWS_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`${REVIEWS_API_ENDPOINT}`, {
          params: queryParams,
          headers: {
            Accept: 'application/ld+json',
          },
        })
          .then(response => {
            commit('FETCH_REVIEWS_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_REVIEWS_ERROR', error)
            reject(error)
          })
      })
    },
    fetchReviewsPending({ commit }, queryParams) {
      commit('FETCH_REVIEWS_PENDING_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`${REVIEWS_API_ENDPOINT}`, {
          params: queryParams,
          headers: {
            Accept: 'application/ld+json',
          },
        })
          .then(response => {
            commit('FETCH_REVIEWS_PENDING_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_REVIEWS_PENDING_ERROR', error)
            reject(error)
          })
      })
    },
    fetchReview({ commit }, { id }) {
      commit('FETCH_REVIEW_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`${REVIEWS_API_ENDPOINT}/${id}`)
          .then(response => {
            commit('FETCH_REVIEW_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_REVIEW_ERROR', error)
            reject(error)
          })
      })
    },
    updateReview({ commit }, review) {
      commit('UPDATE_REVIEW_PENDING')
      return new Promise((resolve, reject) => {
        api.put(`${REVIEWS_API_ENDPOINT}/${review.id}`, review)
          .then(response => {
            commit('DECREASE_REVIEWS_PENDING_AMOUNT')
            commit('FETCH_REVIEW_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('UPDATE_REVIEW_ERROR', error)
            reject(error)
          })
      })
    },
  },
}
