import { PUBLIC_APPOINTMENT_REVIEW_ENDPOINT, PUBLIC_CHECK_APPOINTMENT_REVIEW_STATUS_ENDPOINT } from '@/api/apiUrl'
import { queryGet, queryPost } from '@/api/apiConfig'
import sprintf from 'sprintf-js'

export default {
  namespaced: true,
  state: {
    checkReview: {
      response: {
        status: false,
      },
      isPending: false,
      error: null,
    },
    saveReview: {
      response: null,
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    CHECK_APPOINTMENT_REVIEW_RECEIVED(state, response) {
      state.checkReview.isPending = false
      state.checkReview.response = response.data
      state.checkReview.error = null
    },
    CHECK_APPOINTMENT_REVIEW_PENDING(state) {
      state.checkReview.isPending = true
      state.checkReview.response.status = false
      state.checkReview.error = null
    },
    CHECK_APPOINTMENT_REVIEW_ERROR(state, error) {
      state.checkReview.isPending = false
      state.checkReview.response.status = false
      state.checkReview.error = error
    },
    SAVE_APPOINTMENT_REVIEW_RECEIVED(state, response) {
      state.saveReview.isPending = false
      state.saveReview.response = response.data
      state.saveReview.error = null
    },
    SAVE_APPOINTMENT_REVIEW_PENDING(state) {
      state.saveReview.isPending = true
      state.saveReview.response = null
      state.saveReview.error = null
    },
    SAVE_APPOINTMENT_REVIEW_ERROR(state, error) {
      state.saveReview.isPending = false
      state.saveReview.response = null
      state.saveReview.error = error
    },
  },
  actions: {
    checkPublicAppointmentReview({ commit }, appointmentUuid) {
      commit('CHECK_APPOINTMENT_REVIEW_PENDING')
      return new Promise(() => {
        queryGet(sprintf.sprintf(`${PUBLIC_CHECK_APPOINTMENT_REVIEW_STATUS_ENDPOINT}`, { appointmentUuid }))
          .then(response => {
            commit('CHECK_APPOINTMENT_REVIEW_RECEIVED', response)
          })
          .catch(error => {
            commit('CHECK_APPOINTMENT_REVIEW_ERROR', error)
          })
      })
    },
    saveReview({ commit }, { appointmentUuid, review }) {
      commit('SAVE_APPOINTMENT_REVIEW_PENDING')
      return new Promise(() => {
        queryPost(sprintf.sprintf(`${PUBLIC_APPOINTMENT_REVIEW_ENDPOINT}`, { appointmentUuid }), review)
          .then(response => {
            commit('SAVE_APPOINTMENT_REVIEW_RECEIVED', response)
          })
          .catch(error => {
            commit('SAVE_APPOINTMENT_REVIEW_ERROR', error)
          })
      })
    },
  },
}
