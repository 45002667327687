import Vue from 'vue'
import {
  queryGet, queryPost, queryPut, queryPatch, queryDelete,
} from './apiConfig'

export const useApi = () => new Vue({
  data: {
    refreshTokenPromise: null,
  },
  methods: {
    // not the niciest hack, but it works
    // todo: move this to the lower level, i suppose
    async handleQuery(query, config) {
      // try to query
      try {
        const response = await query()
        return response
      } catch (e) {
        // if unauthorized
        if (e.message.indexOf('code 401') >= 0) {
          // try to refresh the token
          await this.refreshToken(config)
          return this.handleQuery(query)
        }

        // otherwise, just throw
        throw e
      }
    },

    // refreshing the access token
    async refreshToken(config) {
      if (this.refreshTokenPromise) {
        return this.refreshTokenPromise
      }

      this.refreshTokenPromise = new Promise((resolve, reject) => {
        const AUTH0_REFRESH_TOKEN = '/auth0_refresh_stylist_token'
        queryPost(AUTH0_REFRESH_TOKEN, {
          refreshToken: this.$auth.refreshToken,
        }, null, config)
          .then(response => {
            const { success, userToken, refreshToken } = response.data

            if (!success) {
              throw new Error('unable to refresh token')
            }

            this.$auth.apiToken = userToken
            this.$auth.refreshToken = refreshToken
            this.$auth.cacheAuthData()

            this.refreshTokenPromise = null
            resolve(response.data)
          })
          .catch(err => reject(err))
      })

      return this.refreshTokenPromise
    },

    get(url, config) {
      const query = () => queryGet(url, this.$auth.apiToken, config)

      return this.handleQuery(query, config)
    },

    post(url, data, config) {
      const query = () => queryPost(url, data, this.$auth.apiToken, config)

      return this.handleQuery(query, config)
    },

    put(url, data, config) {
      const query = () => queryPut(url, data, this.$auth.apiToken, config)

      return this.handleQuery(query, config)
    },

    patch(url, data, config) {
      const query = () => queryPatch(url, data, this.$auth.apiToken, config)

      return this.handleQuery(query, config)
    },

    delete(url, config) {
      const query = () => queryDelete(url, this.$auth.apiToken, config)

      return this.handleQuery(query, config)
    },
  },
})

export const apiInstance = useApi()

export const apiPlugin = {
  install(AppVue) {
    // eslint-disable-next-line no-param-reassign
    AppVue.prototype.$api = apiInstance
  },
}

export default apiPlugin
