import Vue from 'vue'
import router from '@/router'
import jwtDecode from 'jwt-decode'
import { SUBSCRIPTION_STYLIST_STATUS } from '@/api/apiUrl'
import { ROLE_STYLIST } from '@/auth/userRolesDictionary'
import { getClient, isCallback } from './auth0/client'

// default auth0 data
export const DEFAULT_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
export const DEFAULT_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENTID
export const DEFAULT_REDIRECT_URI = process.env.VUE_APP_AUTH0_REDIRECT_URI
    || (`${window.location.origin}/login-callback`)

const onRedirectCallback = appState => {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

export const useAuthStylist = clientOptions => new Vue({
  methods: {

    // initialize auth0 client
    async initClient() {
      const options = clientOptions || {}

      this.$auth.auth0Client = await getClient({
        domain: DEFAULT_DOMAIN,
        clientId: DEFAULT_CLIENT_ID,
        redirectUri: DEFAULT_REDIRECT_URI,
        ...options,
      })

      if (isCallback()) {
        await this.handleRedirectCallback()
      }
      if ((this.$auth.isAuthenticated && !this.$auth.apiToken) || (this.$auth.isAuthenticated && !this.$auth.roles.includes(ROLE_STYLIST))) {
        try {
          await this.loadUserApiToken()
          await this.loadStylistData()
          await this.verifySubscription()
        } catch (e) {
          this.$auth.error = e
          this.$auth.logout()

          return
        }
      }

      if (this.$auth.isAuthenticated && this.$auth.apiToken) {
        this.$auth.isLogged = true
      }

      if (this.$auth.isAuthenticated) {
        this.$auth.cacheAuthData()
      }

      this.$auth.loading = false
    },

    // handle auth0 callback
    async handleRedirectCallback() {
      try {
        const { appState } = await this.$auth.auth0Client.handleRedirectCallback()
        onRedirectCallback(appState)
      } catch (e) {
        this.$auth.error = e
      } finally {
        this.$auth.isAuthenticated = await this.$auth.auth0Client.isAuthenticated()
        this.$auth.auth0UserData = await this.$auth.auth0Client.getUser()
      }
    },

    // retreive user api token
    async loadUserApiToken() {
      const idTokenClaims = await this.$auth.auth0Client.getIdTokenClaims()
      // eslint-disable-next-line no-underscore-dangle
      const idToken = idTokenClaims.__raw

      if (!idToken) {
        throw new Error('unable to get id token claims')
      }
      const AUTH0_VERIFY_TOKEN = '/auth0_verify_stylist_token'
      const response = await this.$api.post(AUTH0_VERIFY_TOKEN, { idToken })

      const { userToken, refreshToken } = response.data

      if (!userToken) {
        throw new Error('unable to retrieve login data')
      }

      const { stylistId, subdomain, roles } = jwtDecode(userToken)

      if (!stylistId) {
        throw new Error('unable to retrieve login data')
      }

      this.$auth.apiToken = userToken
      this.$auth.stylistId = stylistId
      this.$auth.subdomain = subdomain
      this.$auth.roles = roles
      this.$auth.refreshToken = refreshToken
    },

    // load stylist data from api, needed for checking registration
    async loadStylistData() {
      const { stylistId } = this.$auth
      const response = await this.$api.get(`/api/stylists/${stylistId}`, this.$auth.apiToken)

      this.$auth.isRegistered = typeof response.data.businessName === 'string'
                && response.data.businessName.length > 0
    },

    async verifySubscription() {
      const response = await this.$api.get(SUBSCRIPTION_STYLIST_STATUS)
      const { subscription } = response.data

      this.$auth.isSubscribed = subscription && subscription.status === 'active'
    },
  },
})

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const stylistTokenPlugin = {
  install(AppVue, options) {
    // eslint-disable-next-line no-param-reassign
    AppVue.prototype.$authStylist = useAuthStylist(options)
  },
}

export default stylistTokenPlugin
