import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    customers: {
      response: [],
      isPending: false,
      error: null,
    },
    customer: {
      response: [],
      isPending: false,
      error: null,
    },
    saveCustomer: {
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_CUSTOMERS_RECEIVED(state, response) {
      state.customers.isPending = false
      state.customers.response = response.data
      state.customers.error = null
    },
    FETCH_CUSTOMERS_PENDING(state) {
      state.customers.isPending = true
      state.customers.response = []
      state.customers.error = null
    },
    FETCH_CUSTOMERS_ERROR(state, error) {
      state.customers.isPending = false
      state.customers.response = []
      state.customers.error = error
    },
    FETCH_CUSTOMER_RECEIVED(state, response) {
      state.customer.isPending = false
      state.customer.response = response.data
      state.customer.error = null
    },
    FETCH_CUSTOMER_PENDING(state) {
      state.customer.isPending = true
      state.customer.response = []
      state.customer.error = null
    },
    FETCH_CUSTOMER_ERROR(state, error) {
      state.customer.isPending = false
      state.customer.response = []
      state.customer.error = error
    },
    UPDATE_CUSTOMER_RECEIVED(state) {
      state.customer.isPending = false
      state.customer.error = null
    },
    UPDATE_CUSTOMER_PENDING(state) {
      state.customer.isPending = true
      state.customer.error = null
    },
    UPDATE_CUSTOMER_ERROR(state, error) {
      state.customer.isPending = false
      state.customer.error = error
    },
    DELETE_CUSTOMER_RECEIVED(state) {
      state.customers.isPending = false
      state.customers.error = null
    },
    DELETE_CUSTOMER_PENDING(state) {
      state.customers.isPending = true
      state.customers.error = null
    },
    DELETE_CUSTOMER_ERROR(state, error) {
      state.customers.isPending = false
      state.customers.error = error
    },
    SAVE_CUSTOMER_RECEIVED(state) {
      state.saveCustomer.isPending = false
      state.saveCustomer.error = null
    },
    SAVE_CUSTOMER_PENDING(state) {
      state.saveCustomer.isPending = true
      state.saveCustomer.error = null
    },
    SAVE_CUSTOMER_ERROR(state, error) {
      state.saveCustomer.isPending = false
      state.saveCustomer.error = error
    },
  },
  actions: {
    fetchCustomers({ commit }, queryParams) {
      commit('FETCH_CUSTOMERS_PENDING')
      return new Promise((resolve, reject) => {
        api.get('/api/customers', {
          params: queryParams,
          headers: {
            Accept: 'application/ld+json',
          },
        })
          .then(response => {
            commit('FETCH_CUSTOMERS_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_CUSTOMERS_ERROR', error)
            reject(error)
          })
      })
    },
    fetchCustomer({ commit }, { id }) {
      commit('FETCH_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`/api/customers/${id}`)
          .then(response => {
            commit('FETCH_CUSTOMER_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
    addCustomer({ commit }, customerData) {
      commit('SAVE_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.post('/api/customers', customerData)
          .then(response => {
            commit('SAVE_CUSTOMER_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('SAVE_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
    deleteCustomer({ commit }, customerId) {
      commit('DELETE_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.delete(`/api/customers/${customerId}`)
          .then(response => {
            commit('DELETE_CUSTOMER_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('DELETE_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
    updateCustomer({ commit }, customerData) {
      commit('UPDATE_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.put(`/api/customers/${customerData.id}`, customerData)
          .then(response => {
            commit('UPDATE_CUSTOMER_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('UPDATE_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
  },
}
