import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import StylistSettingStoreModule from '@/components/stylist/stylist-setting/StylistSettingStoreModule'
import StylistCalendarStoreModule from '@/components/stylist/stylist-calendar/StylistCalendarStoreModule'
import StylistCustomerStoreModule from '@/components/stylist/stylist-customers/StylistCustomerStoreModule'
import StylistServiceStoreModule from '@/components/stylist/stylist-service/StylistServiceStoreModule'
import StylistAppointmentStoreModule from '@/components/stylist/stylist-appointment/StylistAppointmentStoreModule'
import StylistReviewStoreModule from '@/components/stylist/stylist-review/StylistReviewStoreModule'
import StylistPageReviewStoreModule from '@/components/stylist/stylist-page-review/StylistPageReviewStoreModule'
import StylistPageStoreModule from '@/components/stylist/stylist-page/StylistPageStoreModule'
import BookingServiceStoreModule from '@/components/booking/booking-service/BookingServiceStoreModule'
import RegisterStoreModule from '@/components/auth/auth-stylist/RegisterStoreModule'
import StylistDashboardStoreModule from '@/components/stylist/stylist-dashboard/StylistDashboardStoreModule'
import SubscriptionStoreModule from '@/components/subscription/SubscriptionStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import AccountData from './stylist/account-data'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    StylistSettingStoreModule,
    StylistCalendarStoreModule,
    StylistCustomerStoreModule,
    StylistServiceStoreModule,
    StylistPageStoreModule,
    BookingServiceStoreModule,
    RegisterStoreModule,
    AccountData,
    StylistAppointmentStoreModule,
    StylistReviewStoreModule,
    StylistPageReviewStoreModule,
    StylistDashboardStoreModule,
    SubscriptionStoreModule,
  },
  strict: process.env.DEV,
})
