import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    appointments: {
      response: [],
      isPending: false,
      totalItems: 0,
      error: null,
    },
    appointment: {
      response: null,
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_APPOINTMENTS_RECEIVED(state, response) {
      state.appointments.isPending = false
      state.appointments.response = response.data['hydra:member']
      state.appointments.totalItems = response.data['hydra:totalItems']
      state.appointments.error = null
    },
    FETCH_APPOINTMENTS_PENDING(state) {
      state.appointments.isPending = true
      state.appointments.response = []
      state.appointments.error = null
    },
    FETCH_APPOINTMENTS_ERROR(state, error) {
      state.appointments.isPending = false
      state.appointments.response = []
      state.appointments.error = error
    },
    FETCH_APPOINTMENT_RECEIVED(state, response) {
      state.appointment.isPending = false
      state.appointment.response = response.data
      state.appointment.error = null
    },
    FETCH_APPOINTMENT_PENDING(state) {
      state.appointment.isPending = true
      state.appointment.response = null
      state.appointment.error = null
    },
    FETCH_APPOINTMENT_ERROR(state, error) {
      state.appointment.isPending = false
      state.appointment.response = null
      state.appointment.error = error
    },
  },
  actions: {
    fetchAppointments({ commit }, queryParams) {
      commit('FETCH_APPOINTMENTS_PENDING')
      return new Promise((resolve, reject) => {
        api.get('/api/appointments', {
          params: queryParams,
          headers: {
            Accept: 'application/ld+json',
          },
        })
          .then(response => {
            commit('FETCH_APPOINTMENTS_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENTS_ERROR', error)
            reject(error)
          })
      })
    },
    fetchAppointment({ commit }, { id }) {
      commit('FETCH_APPOINTMENT_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`/api/appointments/${id}`)
          .then(response => {
            commit('FETCH_APPOINTMENT_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENT_ERROR', error)
            reject(error)
          })
      })
    },
  },
}
