import axios from 'axios'

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const prepareHeaders = (token, _headers = {}) => {
  const headers = {
    ..._headers,
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return headers
}

export const queryInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
})

export const queryGet = (url, token = null, config = {}) => {
  const headers = prepareHeaders(token, config.headers)
  const params = {
    params: {
      timestamp: new Date().getTime(),
      ...config.params,
    },
  }
  return queryInstance.get(url, { ...config, ...params, headers })
}

export const queryPost = (url, data = null, token = null, config = {}) => {
  const headers = prepareHeaders(token, config.headers)
  return queryInstance.post(url, data, { ...config, headers })
}

export const queryPut = (url, data = null, token = null, config = {}) => {
  const headers = prepareHeaders(token, config.headers)
  return queryInstance.put(url, data, { ...config, headers })
}

export const queryPatch = (url, data = null, token = null, config = {}) => {
  const headers = prepareHeaders(token, config.headers)
  headers['Content-Type'] = 'application/merge-patch+json'
  return queryInstance.patch(url, data, { ...config, headers })
}

export const queryDelete = (url, token = null, config = {}) => {
  const headers = prepareHeaders(token, config.headers)
  return queryInstance.delete(url, { ...config, headers })
}
