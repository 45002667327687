import { apiInstance as api } from '@/api/plugin'
import {
  DASHBOARD_APPOINTMENTS_SCHEDULE_ENDPOINT,
  DASHBOARD_STATISTIC_TYPE_ENDPOINT,
} from '@/api/apiUrl'
import DASHBOARD_STATISTICS_TYPES_DICTIONARY from '@/dictionaries/dashboardStatisticsTypesDictionary'
import sprintf from 'sprintf-js'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    totalUsers: {
      response: 0,
      isPending: false,
      error: null,
    },
    appointmentsCountBookedToday: {
      response: 0,
      isPending: false,
      error: null,
    },
    appointmentsSumAmountBookedToday: {
      response: 0,
      isPending: false,
      error: null,
    },
    appointmentsSumAmountSalesToday: {
      response: 0,
      isPending: false,
      error: null,
    },
    scheduleToday: {
      response: [],
      isPending: false,
      error: null,
    },
    scheduleTomorrow: {
      response: [],
      isPending: false,
      error: null,
    },
    byNumbers: {
      response: [],
      isPending: false,
      error: null,
    },
    servicesGroup: {
      response: [],
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_TOTAL_USERS_RECEIVED(state, response) {
      state.totalUsers.isPending = false
      state.totalUsers.response = response.data
      state.totalUsers.error = null
    },
    FETCH_TOTAL_USERS_PENDING(state) {
      state.totalUsers.isPending = true
      state.totalUsers.response = 0
      state.totalUsers.error = null
    },
    FETCH_TOTAL_USERS_ERROR(state, error) {
      state.totalUsers.isPending = false
      state.totalUsers.response = 0
      state.totalUsers.error = error
    },
    FETCH_APPOINTMENTS_COUNT_BOOKED_TODAY_RECEIVED(state, response) {
      state.appointmentsCountBookedToday.isPending = false
      state.appointmentsCountBookedToday.response = response.data
      state.appointmentsCountBookedToday.error = null
    },
    FETCH_APPOINTMENTS_COUNT_BOOKED_TODAY_PENDING(state) {
      state.appointmentsCountBookedToday.isPending = true
      state.appointmentsCountBookedToday.response = 0
      state.appointmentsCountBookedToday.error = null
    },
    FETCH_APPOINTMENTS_COUNT_BOOKED_TODAY_ERROR(state, error) {
      state.appointmentsCountBookedToday.isPending = false
      state.appointmentsCountBookedToday.response = 0
      state.appointmentsCountBookedToday.error = error
    },
    FETCH_APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY_RECEIVED(state, response) {
      state.appointmentsSumAmountBookedToday.isPending = false
      state.appointmentsSumAmountBookedToday.response = response.data
      state.appointmentsSumAmountBookedToday.error = null
    },
    FETCH_APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY_PENDING(state) {
      state.appointmentsSumAmountBookedToday.isPending = true
      state.appointmentsSumAmountBookedToday.response = 0
      state.appointmentsSumAmountBookedToday.error = null
    },
    FETCH_APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY_ERROR(state, error) {
      state.appointmentsSumAmountBookedToday.isPending = false
      state.appointmentsSumAmountBookedToday.response = 0
      state.appointmentsSumAmountBookedToday.error = error
    },
    FETCH_APPOINTMENTS_SUM_AMOUNT_SALES_TODAY_RECEIVED(state, response) {
      state.appointmentsSumAmountSalesToday.isPending = false
      state.appointmentsSumAmountSalesToday.response = response.data
      state.appointmentsSumAmountSalesToday.error = null
    },
    FETCH_APPOINTMENTS_SUM_AMOUNT_SALES_TODAY_PENDING(state) {
      state.appointmentsSumAmountSalesToday.isPending = true
      state.appointmentsSumAmountSalesToday.response = 0
      state.appointmentsSumAmountSalesToday.error = null
    },
    FETCH_APPOINTMENTS_SUM_AMOUNT_SALES_TODAY_ERROR(state, error) {
      state.appointmentsSumAmountSalesToday.isPending = false
      state.appointmentsSumAmountSalesToday.response = 0
      state.appointmentsSumAmountSalesToday.error = error
    },
    FETCH_SCHEDULE_TODAY_RECEIVED(state, response) {
      state.scheduleToday.isPending = false
      state.scheduleToday.response = response.data
      state.scheduleToday.error = null
    },
    FETCH_SCHEDULE_TODAY_PENDING(state) {
      state.scheduleToday.isPending = true
      state.scheduleToday.response = []
      state.scheduleToday.error = null
    },
    FETCH_SCHEDULE_TODAY_ERROR(state, error) {
      state.scheduleToday.isPending = false
      state.scheduleToday.response = []
      state.scheduleToday.error = error
    },
    FETCH_SCHEDULE_TOMORROW_RECEIVED(state, response) {
      state.scheduleTomorrow.isPending = false
      state.scheduleTomorrow.response = response.data
      state.scheduleTomorrow.error = null
    },
    FETCH_SCHEDULE_TOMORROW_PENDING(state) {
      state.scheduleTomorrow.isPending = true
      state.scheduleTomorrow.response = []
      state.scheduleTomorrow.error = null
    },
    FETCH_SCHEDULE_TOMORROW_ERROR(state, error) {
      state.scheduleTomorrow.isPending = false
      state.scheduleTomorrow.response = []
      state.scheduleTomorrow.error = error
    },
    FETCH_BY_NUMBERS_RECEIVED(state, response) {
      state.byNumbers.isPending = false
      state.byNumbers.response = response.data
      state.byNumbers.error = null
    },
    FETCH_BY_NUMBERS_PENDING(state) {
      state.byNumbers.isPending = true
      state.byNumbers.response = []
      state.byNumbers.error = null
    },
    FETCH_BY_NUMBERS_ERROR(state, error) {
      state.byNumbers.isPending = false
      state.byNumbers.response = []
      state.byNumbers.error = error
    },
    FETCH_SERVICES_GROUP_RECEIVED(state, response) {
      state.servicesGroup.isPending = false
      state.servicesGroup.response = response.data
      state.servicesGroup.error = null
    },
    FETCH_SERVICES_GROUP_PENDING(state) {
      state.servicesGroup.isPending = true
      state.servicesGroup.response = []
      state.servicesGroup.error = null
    },
    FETCH_SERVICES_GROUP_ERROR(state, error) {
      state.servicesGroup.isPending = false
      state.servicesGroup.response = []
      state.servicesGroup.error = error
    },
  },
  actions: {
    fetchTotalUsers({ commit }) {
      commit('FETCH_TOTAL_USERS_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_STATISTIC_TYPE_ENDPOINT}`, { type: DASHBOARD_STATISTICS_TYPES_DICTIONARY.TOTAL_USERS }))
          .then(response => {
            commit('FETCH_TOTAL_USERS_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_TOTAL_USERS_ERROR', error)
            reject(error)
          })
      })
    },
    fetchAppointmentsCountBookedToday({ commit }) {
      commit('FETCH_APPOINTMENTS_COUNT_BOOKED_TODAY_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_STATISTIC_TYPE_ENDPOINT}`, { type: DASHBOARD_STATISTICS_TYPES_DICTIONARY.APPOINTMENTS_COUNT_BOOKED_TODAY }))
          .then(response => {
            commit('FETCH_APPOINTMENTS_COUNT_BOOKED_TODAY_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENTS_COUNT_BOOKED_TODAY_ERROR', error)
            reject(error)
          })
      })
    },
    fetchAppointmentsSumAmountBookedToday({ commit }) {
      commit('FETCH_APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_STATISTIC_TYPE_ENDPOINT}`, { type: DASHBOARD_STATISTICS_TYPES_DICTIONARY.APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY }))
          .then(response => {
            commit('FETCH_APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENTS_SUM_AMOUNT_BOOKED_TODAY_ERROR', error)
            reject(error)
          })
      })
    },
    fetchAppointmentsSumAmountSalesToday({ commit }) {
      commit('FETCH_APPOINTMENTS_SUM_AMOUNT_SALES_TODAY_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_STATISTIC_TYPE_ENDPOINT}`, { type: DASHBOARD_STATISTICS_TYPES_DICTIONARY.APPOINTMENTS_SUM_AMOUNT_SALES_TODAY }))
          .then(response => {
            commit('FETCH_APPOINTMENTS_SUM_AMOUNT_SALES_TODAY_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENTS_SUM_AMOUNT_SALES_TODAY_ERROR', error)
            reject(error)
          })
      })
    },
    fetchScheduleToday({ commit }) {
      commit('FETCH_SCHEDULE_TODAY_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_APPOINTMENTS_SCHEDULE_ENDPOINT}`, { date: moment().format('yyyy-MM-DD') }))
          .then(response => {
            commit('FETCH_SCHEDULE_TODAY_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_SCHEDULE_TODAY_ERROR', error)
            reject(error)
          })
      })
    },
    fetchScheduleTomorrow({ commit }) {
      commit('FETCH_SCHEDULE_TOMORROW_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_APPOINTMENTS_SCHEDULE_ENDPOINT}`, { date: moment().add(1, 'days').format('yyyy-MM-DD') }))
          .then(response => {
            commit('FETCH_SCHEDULE_TOMORROW_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_SCHEDULE_TOMORROW_ERROR', error)
            reject(error)
          })
      })
    },
    fetchByNumbers({ commit }) {
      commit('FETCH_BY_NUMBERS_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_STATISTIC_TYPE_ENDPOINT}`, { type: DASHBOARD_STATISTICS_TYPES_DICTIONARY.BY_NUMBERS }))
          .then(response => {
            commit('FETCH_BY_NUMBERS_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_BY_NUMBERS_ERROR', error)
            reject(error)
          })
      })
    },
    fetchServicesGroup({ commit }) {
      commit('FETCH_SERVICES_GROUP_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${DASHBOARD_STATISTIC_TYPE_ENDPOINT}`, { type: DASHBOARD_STATISTICS_TYPES_DICTIONARY.APPOINTMENTS_SERVICES_GROUP }))
          .then(response => {
            commit('FETCH_SERVICES_GROUP_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_SERVICES_GROUP_ERROR', error)
            reject(error)
          })
      })
    },
  },
}
