import Vue from 'vue'
import Cookies from 'vue-cookies'
import { parseDomain, ParseResultType } from 'parse-domain'
import jwtDecode from 'jwt-decode'
import { getClient } from '@/auth/auth0/client'
import { DEFAULT_CLIENT_ID, DEFAULT_DOMAIN, DEFAULT_REDIRECT_URI } from '@/auth/customerToken'

// Cookies
Vue.use(Cookies)

export const useAuth = () => new Vue({
  data() {
    return {
      loading: true,

      // is auth0 user authenticated
      isAuthenticated: false,

      // any auth0 user-related data
      auth0UserData: {},

      // auth0 api client
      auth0Client: null,

      // beautybook api token
      apiToken: null,

      // is logged in api
      isLogged: false,

      // is subscribed in stripe
      isSubscribed: false,

      // beautybook stylist data
      stylistId: null,
      subdomain: null,
      stylistData: {},
      isRegistered: false,

      roles: null,

      // error, of null if everything is allright
      error: null,

      // current domain and subdomain
      currentDomain: null,
      currentSubdomain: null,
    }
  },

  async created() {
    this.loadCachedAuthData()
    this.setDomainAndSubdomain()
    this.loading = false
  },

  methods: {
    async init() {
      this.$auth.auth0Client = await getClient({
        domain: DEFAULT_DOMAIN,
        clientId: DEFAULT_CLIENT_ID,
        redirectUri: DEFAULT_REDIRECT_URI,
      })
    },
    loadCachedAuthData() {
      const apiToken = this.$cookies.get('api_token')
      const isRegistered = this.$cookies.get('is_registered')
      const isSubscribed = this.$cookies.get('is_subscribed')
      const refreshToken = this.$cookies.get('refresh_token')
      if (!apiToken) {
        return
      }
      const { stylistId, subdomain, roles } = jwtDecode(apiToken)
      this.apiToken = apiToken
      this.stylistId = parseInt(stylistId, 10)
      this.isRegistered = isRegistered === 'true'
      this.isSubscribed = isSubscribed === 'true'
      this.isAuthenticated = true
      this.isLogged = true
      this.subdomain = subdomain
      this.roles = roles
      this.refreshToken = refreshToken
    },
    // login to auth0 using redirect
    loginWithRedirect(options) {
      this.auth0Client.loginWithRedirect(options)
    },
    // logout auth0 user
    logout(options) {
      sessionStorage.clear()
      this.$cookies.remove('api_token', '', this.currentDomain)
      this.$cookies.remove('is_registered', '', this.currentDomain)
      this.$cookies.remove('is_subscribed', '', this.currentDomain)
      this.$cookies.remove('refresh_token', '', this.currentDomain)
      this.init().then(() => {
        this.auth0Client.logout(options)
      })
    },
    setRegistered() {
      this.isRegistered = true

      this.cacheAuthData()
    },
    setSubscribed() {
      this.isSubscribed = true

      this.cacheAuthData()
    },
    setDomainAndSubdomain() {
      const parseDomainResult = parseDomain(window.location.hostname)

      if (parseDomainResult.type === ParseResultType.Listed) {
        const { subDomains, domain, topLevelDomains } = parseDomainResult
        this.currentDomain = `${domain}.${topLevelDomains}`
        this.currentSubdomain = typeof subDomains[0] !== 'undefined' && subDomains[0] !== 'www' ? subDomains[0] : null
      }
    },
    cacheAuthData() {
      this.$cookies.set('api_token', this.apiToken, '2h', '', this.currentDomain)
      this.$cookies.set('is_registered', this.isRegistered, '2h', '', this.currentDomain)
      this.$cookies.set('is_subscribed', this.isSubscribed, '2h', '', this.currentDomain)
      this.$cookies.set('refresh_token', this.refreshToken, '48h', '', this.currentDomain)
    },
  },
})

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const auth0Plugin = {
  install(AppVue, options) {
    // eslint-disable-next-line no-param-reassign
    AppVue.prototype.$auth = useAuth(options)
  },
}

export default auth0Plugin
