import { queryGet } from '@/api/apiConfig'
import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    stylist: {
      response: {
        id: null,
        name: null,
        lastname: null,
        subdomain: null,
        businessName: null,
        businessDescription: null,
        facebookUrl: null,
        twitterUrl: null,
        instagramUrl: null,
        address: null,
        city: null,
        postCode: null,
        latitude: null,
        longtitude: null,
        stripeId: null,
        stripeAccountEnabled: null,
        timeZone: {
          id: null,
          name: null,
          value: null,
        },
        email: null,
      },
      isPending: false,
      error: null,
    },
    openHours: {
      response: null,
      isPending: false,
      error: null,
    },
    services: {
      response: null,
      isPending: false,
      error: null,
    },
    profileImg: {
      response: null,
      isPending: false,
      error: null,
    },
    backgroundImg: {
      response: null,
      isPending: false,
      error: null,
    },
    reviews: {
      response: null,
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_STYLIST_RECEIVED(state, response) {
      state.stylist.isPending = false
      state.stylist.response = response.data
      state.stylist.error = null
    },
    FETCH_STYLIST_PENDING(state) {
      state.stylist.isPending = true
      state.stylist.response = []
      state.stylist.error = null
    },
    FETCH_STYLIST_ERROR(state, error) {
      state.stylist.isPending = false
      state.stylist.response = []
      state.stylist.error = error
    },
    FETCH_OPEN_HOURS_RECEIVED(state, response) {
      state.openHours.isPending = false
      state.openHours.response = response.data
      state.openHours.error = null
    },
    FETCH_OPEN_HOURS_PENDING(state) {
      state.openHours.isPending = true
      state.openHours.response = null
      state.openHours.error = null
    },
    FETCH_OPEN_HOURS_ERROR(state, error) {
      state.openHours.isPending = false
      state.openHours.response = null
      state.openHours.error = error
    },
    FETCH_SERVICES_RECEIVED(state, response) {
      state.services.isPending = false
      state.services.response = response.data
      state.services.error = null
    },
    FETCH_SERVICES_PENDING(state) {
      state.services.isPending = true
      state.services.response = null
      state.services.error = null
    },
    FETCH_SERVICES_ERROR(state, error) {
      state.services.isPending = false
      state.services.response = null
      state.services.error = error
    },
    FETCH_REVIEWS_RECEIVED(state, response) {
      state.reviews.isPending = false
      state.reviews.response = response.data
      state.reviews.error = null
    },
    FETCH_REVIEWS_PENDING(state) {
      state.reviews.isPending = true
      state.reviews.response = null
      state.reviews.error = null
    },
    FETCH_REVIEWS_ERROR(state, error) {
      state.reviews.isPending = false
      state.reviews.response = null
      state.reviews.error = error
    },
    UPDATE_PROFILE_IMAGE_PENDING(state) {
      state.profileImg.isPending = true
      state.profileImg.response = null
      state.profileImg.error = null
    },
    UPDATE_PROFILE_IMAGE_RECEIVED(state) {
      state.profileImg.isPending = false
      state.profileImg.response = true
      state.profileImg.error = null
    },
    UPDATE_PROFILE_IMAGE_ERROR(state) {
      state.profileImg.isPending = false
      state.profileImg.response = null
      state.profileImg.error = true
    },
    UPDATE_BACKGROUND_IMAGE_PENDING(state) {
      state.backgroundImg.isPending = true
      state.backgroundImg.response = null
      state.backgroundImg.error = null
    },
    UPDATE_BACKGROUND_IMAGE_RECEIVED(state) {
      state.backgroundImg.isPending = false
      state.backgroundImg.response = true
      state.backgroundImg.error = null
    },
    UPDATE_BACKGROUND_IMAGE_ERROR(state) {
      state.backgroundImgs.isPending = false
      state.backgroundImg.response = null
      state.backgroundImg.error = true
    },
  },
  actions: {
    fetchPublicStylist({ commit }, subdomain) {
      commit('FETCH_STYLIST_PENDING')
      return new Promise(() => {
        queryGet(`/api/public/stylists/subdomain/${subdomain}`)
          .then(response => {
            commit('FETCH_STYLIST_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_STYLIST_ERROR', error)
          })
      })
    },
    fetchPublicOpenHours({ commit }) {
      commit('FETCH_OPEN_HOURS_PENDING')
      return new Promise(() => {
        queryGet('/api/public/open_hours')
          .then(response => {
            commit('FETCH_OPEN_HOURS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_OPEN_HOURS_ERROR', error)
          })
      })
    },
    fetchPublicServices({ commit }) {
      commit('FETCH_SERVICES_PENDING')
      return new Promise(() => {
        queryGet('/api/public/services')
          .then(response => {
            commit('FETCH_SERVICES_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_SERVICES_ERROR', error)
          })
      })
    },
    fetchPublicReviews({ commit }) {
      commit('FETCH_REVIEWS_PENDING')
      return new Promise(() => {
        queryGet('/api/public/rates')
          .then(response => {
            commit('FETCH_REVIEWS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_REVIEWS_ERROR', error)
          })
      })
    },
    updateProfileImage({ commit }, { subdomain, formData }) {
      commit('UPDATE_PROFILE_IMAGE_PENDING')
      return new Promise((resolve, reject) => {
        api.post(`/api/stylist-profile-img-update/${subdomain}`, formData)
          .then(() => {
            commit('UPDATE_PROFILE_IMAGE_RECEIVED')
            resolve()
          })
          .catch(() => {
            commit('UPDATE_PROFILE_IMAGE_ERROR')
            reject()
          })
      })
    },
    updateBackgroundImage({ commit }, { subdomain, formData }) {
      commit('UPDATE_BACKGROUND_IMAGE_PENDING')
      return new Promise((resolve, reject) => {
        api.post(`/api/stylist-bg-img-update/${subdomain}`, formData)
          .then(() => {
            commit('UPDATE_BACKGROUND_IMAGE_RECEIVED')
            resolve()
          })
          .catch(() => {
            commit('UPDATE_BACKGROUND_IMAGE_ERROR')
            reject()
          })
      })
    },
  },
}
