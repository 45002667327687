<template>
  <b-overlay
    :show="loading"
    bg-color="#ffffff"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-card-code title="Account">
      <div>
        <validation-observer
          ref="settingsForm"
        >

          <b-form
            class="mt-1"
            @submit.prevent="submitForm"
          >
            <b-row>
              <!-- Field: Business name -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Business name"
                  label-for="businessName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="businessName"
                    vid="businessName"
                    rules="required"
                  >
                    <b-form-input
                      id="businessName"
                      v-model="localData.businessName"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: subdomain -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Subdomain"
                  label-for="subdomain"
                >
                  <b-form-input
                    id="subdomain"
                    v-model="localData.subdomain"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Field: Name -->
              <b-col
                cols="6"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="localData.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Last name -->
              <b-col
                cols="6"
              >
                <b-form-group
                  label="Last name"
                  label-for="lastname"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last name"
                    vid="lastname"
                    rules="required"
                  >
                    <b-form-input
                      id="lastname"
                      v-model="localData.lastname"
                      :state="errors[0] ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Email -->
            <b-row>
              <b-col
                cols="12"
                md="12"
                lg="12"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="localData.email"
                      :state="errors[0] ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Field: Facebook -->
            <b-row>
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Facebook URL"
                  label-for="facebookUrl"
                >
                  <b-form-input
                    id="facebookUrl"
                    v-model="localData.facebookUrl"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Twitter -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Twitter URL"
                  label-for="twitterUrl"
                >
                  <b-form-input
                    id="twitterUrl"
                    v-model="localData.twitterUrl"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Instagram -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Instagram URL"
                  label-for="instagramUrl"
                >
                  <b-form-input
                    id="instagramUrl"
                    v-model="localData.instagramUrl"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row v-if="showMap">
              <b-col cols="12">
                <vl-map
                  ref="vlMap"
                  :load-tiles-while-animating="true"
                  :load-tiles-while-interacting="true"
                  style="height: 400px"
                  data-projection="EPSG:4326"
                  @click="mapClick"
                >
                  <vl-view
                    :zoom.sync="zoom"
                    :center.sync="center"
                    :rotation.sync="rotation"
                  />

                  <vl-geoloc @update:position="updateGeolocPosition">
                    <template slot-scope="geoloc">
                      <vl-feature
                        v-if="geoloc.position"
                        id="position-feature"
                      >
                        <vl-geom-point :coordinates="geoloc.position" />
                        <vl-style-box>
                          <vl-style-icon
                            src="_media/marker.png"
                            :scale="0.4"
                            :anchor="[0.5, 1]"
                          />
                        </vl-style-box>
                      </vl-feature>
                    </template>
                  </vl-geoloc>

                  <vl-layer-tile id="osm">
                    <vl-source-osm />
                  </vl-layer-tile>

                  <vl-overlay
                    v-if="
                      typeof targetPosition === 'object'
                        && targetPosition !== null
                        && targetPosition.length === 2
                    "
                    id="target"
                    :position="targetPosition"
                  >
                    <template>
                      <div
                        class="overlay-content"
                        @click="overlayClick"
                      />
                    </template>
                  </vl-overlay>

                </vl-map>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Time zone -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Time Zone"
                  label-for="timeZone"
                >
                  <validation-provider
                    #default="{ errors }"
                    mode="passive"
                    name="Time Zone"
                    rules="required"
                  >
                    <b-form-select
                      v-model="localData.timeZone"
                      name="timeZone"
                      :options="timeZonesDictionary"
                      :state="errors[0] ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Field: Address -->
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Address"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="localData.address"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Field: postCode -->
              <b-col
                cols="4"
              >
                <b-form-group
                  label="Postal code"
                  label-for="postCode"
                >
                  <b-form-input
                    id="postCode"
                    v-model="localData.postCode"
                  />
                </b-form-group>
              </b-col>

              <!-- field: city -->
              <b-col
                cols="8"
              >
                <b-form-group
                  label="City"
                  label-for="city"
                >
                  <b-form-input
                    id="city"
                    v-model="localData.city"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Field: Business phone -->
              <b-col
                cols="12"
              >
                <b-form-group
                  label="Business phone"
                  label-for="businessPhone"
                >
                  <b-form-input
                    id="businessPhone"
                    v-model="localData.businessPhone"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="showLatLong">
              <!-- Field: Latitude -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Latitude"
                  label-for="latitude"
                >
                  <b-form-input
                    id="latitude"
                    v-model="localData.latitude"
                    readonly
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Longtitude -->
              <b-col
                cols="12"
                md="6"
                lg="6"
              >
                <b-form-group
                  label="Longtitude"
                  label-for="longtitude"
                >
                  <b-form-input
                    id="longtitude"
                    v-model="localData.longtitude"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  type="submit"
                  :disabled="saving"
                >
                  Save changes
                </b-button>
                <div
                  v-if="saving"
                  class="mb-1 d-inline"
                >
                  <b-spinner small />
                </div>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </div>
    </b-card-code>
  </b-overlay>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Vuelayers, {
  Map,
  TileLayer,
  OsmSource,
  Overlay,
  Geoloc,
} from 'vuelayers'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import BCardCode from '@core/components/b-card-code'
import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormInput,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vuelayers(Vue)
Vue.use(Geoloc)

export default {
  components: {
    BCardCode,
    BSpinner,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    Map,
    // eslint-disable-next-line vue/no-unused-components
    TileLayer,
    // eslint-disable-next-line vue/no-unused-components
    OsmSource,
    // eslint-disable-next-line vue/no-unused-components
    Overlay,
    // eslint-disable-next-line vue/no-unused-components
    Geoloc,
    // validations -->
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    required,
    // eslint-disable-next-line vue/no-unused-components
    email,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localData: {},
      zoom: 2,
      center: [0, 0],
      rotation: 0,
      targetPosition: false,
      showLatLong: false,
      showMap: false,
      timeZonesDictionary: [],
      timeZone: null,
    }
  },
  computed: {
    ...mapState('AccountData', ['stylistData', 'loaded', 'loading', 'saving']),
    ...mapState('StylistSettingStoreModule', {
      timeZonesDictionaryStore: state => state.timeZonesDictionary,
    }),
  },
  watch: {
    currentTab() {
      this.$refs.vlMap.mount()
    },
    timeZonesDictionaryStore: {
      deep: true,
      handler(newTimeZonesDictionary) {
        if (newTimeZonesDictionary.response) {
          this.timeZonesDictionary = newTimeZonesDictionary.response.map(timeZone => ({
            value: timeZone.id,
            text: timeZone.name,
          }))
        }
      },
    },
    stylistData: {
      deep: true,
      handler(newStylistData) {
        const timeZoneId = newStylistData.timeZone ? newStylistData.timeZone.id : null
        this.localData = { ...newStylistData, timeZone: timeZoneId }
        this.targetPosition = [
          parseFloat(this.localData.latitude),
          parseFloat(this.localData.longtitude),
        ]
      },
    },
  },
  mounted() {
    this.fetchTimeZonesDictionary()
  },
  methods: {
    ...mapActions('AccountData', ['saveStylistData']),
    ...mapActions('StylistSettingStoreModule', [
      'fetchTimeZonesDictionary',
    ]),
    mapClick(event) {
      const { coordinate } = event

      this.setCoordinate(coordinate)
    },
    setCoordinate(coordinate) {
      const [latitude, longtitude] = coordinate.map(t => t.toString())

      this.targetPosition = coordinate
      this.localData = {
        ...this.localData,
        timeZone: this.stylistData.timeZone.id,
        latitude,
        longtitude,
      }
    },
    overlayClick() {
      this.targetPosition = null
      this.localData.longtitude = null
      this.localData.latitude = null
    },
    async saveChanges() {
      try {
        await this.saveStylistData(this.localData)
        this.localData = { ...this.stylistData, timeZone: this.stylistData.timeZone.id }
        this.success()
      } catch (e) {
        this.fail()
      }
    },
    async submitForm() {
      const validated = await this.$refs.settingsForm.validate()
      if (!validated) {
        return
      }
      await this.saveChanges()
    },
    async updateGeolocPosition(coordinate) {
      if (this.localData.latitude && this.localData.longtitude) {
        return
      }
      this.setCoordinate(coordinate)
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Stylist settings had been updated',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    fail() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Stylist settings update failed',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
  height: 100% !important;
}
.overlay-content {
  border-radius: 100%;
  border: 2px solid red;
  background-color: white;
  min-width: 12px;
  min-height: 12px;
  display: block;
  transform: translateX(-6px) translateY(-6px);
}
</style>
