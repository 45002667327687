export const APPOINTMENTS_API_ENDPOINT = '/api/appointments'
export const REVIEWS_API_ENDPOINT = '/api/rates'
export const CUSTOMERS_API_ENDPOINT = '/api/customers'
export const SERVICES_API_ENDPOINT = '/api/services'
export const OPEN_HOURS_API_ENDPOINT = '/api/open_hours'
export const APPOINTMENT_PAYMENT_CHECKOUT_API_ENDPOINT = '/api/appointment/%(appointmentId)s/payment/checkout'
export const APPOINTMENT_CARD_DETAILS_API_ENDPOINT = '/api/appointment/%(id)s/payment/card/details'
export const APPOINTMENT_PAYMENT_CANCEL_FEE_API_ENDPOINT = 'api/appointment/%(appointmentId)s/payment/cancel-fee'
export const APPOINTMENT_PAYMENT_NO_SHOW_FEE_API_ENDPOINT = 'api/appointment/%(appointmentId)s/payment/no-show-fee'
export const APPOINTMENT_PAYMENT_FEE_AVAILABILITY_API_ENDPOINT = 'api/appointment/%(appointmentId)s/payment/%(type)s/availability'
export const DASHBOARD_STATISTIC_TYPE_ENDPOINT = 'api/dashboard/statistics/%(type)s'
export const DASHBOARD_APPOINTMENTS_SCHEDULE_ENDPOINT = 'api/appointments/schedule/%(date)s'
export const PUBLIC_SERVICES_API_ENDPOINT = '/api/public/services'
export const PUBLIC_CHECK_APPOINTMENT_REVIEW_STATUS_ENDPOINT = '/api/public/appointment/%(appointmentUuid)s/review/status'
export const PUBLIC_APPOINTMENT_REVIEW_ENDPOINT = '/api/public/appointment/%(appointmentUuid)s/review'
export const LOGGED_CUSTOMER_BLOCK_SELECTED_DATE_TIME_API_ENDPOINT = '/api/logged-customer/block-date'
export const LOGGED_CUSTOMER_APPOINTMENTS_API_ENDPOINT = '/api/logged-customer/appointments'
export const LOGGED_CUSTOMER_APPOINTMENT_API_ENDPOINT = '/api/logged-customer/appointment'
export const LOGGED_CUSTOMER_CUSTOMER_API_ENDPOINT = '/api/logged-customer/customer'
export const LOGGED_CUSTOMER_PAYMENT_CREATE_CUSTOMER = '/api/logged-customer/payment/create-customer'
export const LOGGED_CUSTOMER_PAYMENT_ADD_CARD_CUSTOMER = '/api/logged-customer/payment/customer/add-card'
export const LOGGED_CUSTOMER_STYLIST_POLICIES_API_ENDPOINT = '/api/logged-customer/stylist-policy-config'
export const LOGGED_CUSTOMER_PAYMENT_CUSTOMER_CARDS_LIST = '/api/logged-customer/payment/customer/cards-list'
export const LOGGED_CUSTOMER_APPOINTMENT_CONFIRM = '/api/logged-customer/appointment/confirm'
export const LOGGED_CUSTOMER_APPOINTMENT_BY_UUID_ENDPOINT = '/api/logged-customer/appointment-by-uuid'
export const LOGGED_CUSTOMER_SERVICES_REQUEST_CONSULTATION = '/api/logged-customer/services/request-consultation'
export const SUBSCRIPTION_STYLIST_LIST = '/api/subscription/stylist/list'
export const SUBSCRIPTION_STYLIST_CHECKOUT = '/api/subscription/stylist/checkout'
export const SUBSCRIPTION_STYLIST_STATUS = '/api/subscription/stylist'
export const SUBSCRIPTION_STYLIST_START_TRIAL = '/api/subscription/stylist/start-trial'
export const SUBSCRIPTION_STYLIST_USE_PROMO_CODE = '/api/subscription/stylist/check-promo-code'
export const SUBSCRIPTION_STYLIST_APPLY_PROMO_CODE = '/api/subscription/stylist/apply-promo-code'
