<template>
  <b-overlay
    :show="!stylistData.id"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-card-code
      title="Accepting payments"
    >
      <div>
        <div v-if="!stylistData.stripeId || !stripeAccount.charges_enabled || !stylistData.stripeAccountEnabled">
          <p>Accepting payments allow you to enable no-show protection and use CC information provided by your customers to charge for your services.</p>
          <br>
          <p>
            To accept payments you need to configure Stripe for this account. Please click button below to begin Stripe onboarding.<br>
            You will be redirected back to this page upon completion.
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            :disabled="connectStripeAccountStore.isPending || connectStripeAccountStore.response"
            @click="connectionStripeAccount"
          >
            Connect with Stripe
          </b-button>
          <div
            v-if="connectStripeAccountStore.isPending"
            class="mb-1 d-inline"
          >
            <b-spinner small />
          </div>
        </div>

        <div
          v-if="stylistData.stripeId && stripeAccount.charges_enabled && stylistData.stripeAccountEnabled"
        >
          <p>
            Your stripe account has been configured succesfully with following id: {{ stylistData.stripeId }}.<br>
            To unlink your Stripe account from the Beautybook account, click button below. You will be able to reconnect to the same account later on.
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            @click="disconnectStripeAccount()"
          >
            Unlink Stripe
          </b-button>
          <div
            v-if="stylistSaving"
            class="mb-1 d-inline"
          >
            <b-spinner small />
          </div>
        </div>
      </div>
    </b-card-code>
  </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BOverlay,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BCardCode,
    BOverlay,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stripeAccount: {},
      stylistData: {},
    }
  },
  computed: {
    ...mapState('StylistSettingStoreModule', {
      connectStripeAccountStore: state => state.connectStripeAccount,
      stripeAccountStore: state => state.stripeAccount,
    }),
    ...mapState('AccountData', {
      stylistDataStore: state => state.stylistData,
      stylistSaving: state => state.saving,
    }),
  },
  watch: {
    connectStripeAccountStore: {
      deep: true,
      handler(newStripeAccount) {
        if (newStripeAccount.response) {
          window.location.href = `${newStripeAccount.response.url}`
        }
      },
    },
    stylistDataStore: {
      deep: true,
      handler(newStylistData) {
        if (newStylistData) {
          this.stylistData = newStylistData
        }
      },
    },
    stripeAccountStore: {
      deep: true,
      handler(newStripeAccount) {
        if (newStripeAccount.response) {
          this.stripeAccount = newStripeAccount.response
        }
      },
    },
  },
  mounted() {
    this.fetchStripeAccount()
  },
  methods: {
    ...mapActions('AccountData', ['saveStylistData']),
    ...mapActions('StylistSettingStoreModule', [
      'connectStripeAccount',
      'fetchStripeAccount',
    ]),
    connectionStripeAccount() {
      this.connectStripeAccount()
    },
    async disconnectStripeAccount() {
      const stylistData = { stylistId: this.stylistData.stylistId, stripeAccountEnabled: false }
      try {
        await this.saveStylistData(stylistData)
        this.success('Data has been updated')
      } catch (e) {
        this.error('Something went wrong. Please try again.')
      }
    },
    success(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    error(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
