export const AUTH_LOGOUT_PATH = '/logout'
export const AUTH_REGISTER_PATH = '/register'
export const AUTH_CALLBACK_PATH = '/login-callback'
export const AUTH_LOGIN_PATH = '/login'
export const AUTH_CUSTOMER_CALLBACK_PATH = '/login-customer-callback'
export const AUTH_CUSTOMER_LOGIN_PATH = '/login-customer'
export const APPS_CALENDAR = '/apps/calendar'
export const COMMON_ERROR_404 = '/error-404'
export const COMMON_ERROR_403 = '/error-403'
export const STYLIST_SETTINGS_PATH = '/apps/stylist/setting'
export const SERVICES_BOOK_PATH = '/services/book'
export const SERVICES_BOOKING_PATH = '/services/booking'
export const SERVICES_BOOKINGS_PATH = '/services/bookings'
export const SERVICES_REQUEST_CONSULTATION_PATH = '/services/:id/request-consultation'
export const TERMS_OF_SERVICE_PATH = '/tos'
export const APPOINTMENT_REVIEW_PATH = '/appointment/:appointmentUuid/review'
export const APPS_DASHBOARD_PATH = '/apps/dashboard'
export const SUBSCRIPTION_PLANS_PATH = '/subscription/plans'
export const SUBSCRIPTION_RETURN_PATH = '/subscription/return'
export const APPS_STYLIST_DEFAULT = APPS_DASHBOARD_PATH

export default {
  AUTH_LOGOUT_PATH,
  AUTH_REGISTER_PATH,
  AUTH_CALLBACK_PATH,
  AUTH_CUSTOMER_CALLBACK_PATH,
  AUTH_LOGIN_PATH,
  AUTH_CUSTOMER_LOGIN_PATH,
  APPS_CALENDAR,
  COMMON_ERROR_404,
  COMMON_ERROR_403,
  STYLIST_SETTINGS_PATH,
  SERVICES_BOOK_PATH,
  SERVICES_BOOKING_PATH,
  SERVICES_BOOKINGS_PATH,
  SERVICES_REQUEST_CONSULTATION_PATH,
  TERMS_OF_SERVICE_PATH,
  APPS_STYLIST_DEFAULT,
  APPOINTMENT_REVIEW_PATH,
  APPS_DASHBOARD_PATH,
  SUBSCRIPTION_PLANS_PATH,
  SUBSCRIPTION_RETURN_PATH,
}
