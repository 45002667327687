<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewServiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-service-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Service
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="serviceData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required|regex:^(([1-9])*([0])*)(\.(\d{1})(\d{1})?)?$|greaterThanZero"
          >
            <b-form-group
              label="Price ($)"
              label-for="price"
            >
              <b-form-input
                id="last-name"
                v-model="serviceData.price"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Hourly -->
          <validation-provider
            #default="validationContext"
            name="hourly"
            rules="required"
          >
            <b-form-group
              label="Hourly"
              label-for="hourly"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="serviceData.hourly"
                :options="[{
                  value: 0,
                  label: 'No'
                }, {
                  value: 1,
                  label: 'Yes'
                }]"
                :reduce="gender => gender.value"
                :clearable="false"
                input-id="hourly"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="duration"
            rules="required|integer|regex:^[1-9]\d*$"
          >
            <b-form-group
              label="Duration (m)"
              label-for="duration"
            >
              <b-form-input
                id="duration"
                v-model="serviceData.duration"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="category"
            rules="required"
          >
            <b-form-group
              label-for="categoryList"
              label="Category"
              :state="getValidationState(validationContext)"
            >
              <treeselect
                id="category"
                v-model="serviceData.category"
                :options="options"
                :auto-load-root-options="true"
                :value="value"
                :disable-branch-nodes="false"
                :searchable="false"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
          <!--/ Category -->

          <!-- Public -->
          <validation-provider
            #default="validationContext"
            name="public"
            rules="required"
          >
            <b-form-group
              label="Public"
              label-for="public"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="serviceData.public"
                :options="[{
                  value: 0,
                  label: 'No'
                }, {
                  value: 1,
                  label: 'Yes'
                }]"
                :reduce="gender => gender.value"
                :clearable="false"
                input-id="public"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <div
              v-if="saveServiceStore.isPending"
              class="text-center"
            >
              <b-button
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner small />
                Saving...
              </b-button>
            </div>
            <div v-if="!saveServiceStore.isPending">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, integer, positive,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { mapActions, mapState } from 'vuex'
import serviceStoreModule from './StylistServiceStoreModule'

extend('greaterThanZero', {
  validate(value) {
    return value > 0
  },
  computesRequired: true,
  message: 'Price must be greater than 0',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    Treeselect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewServiceSidebarActive',
    event: 'update:is-add-new-service-sidebar-active',
  },
  props: {
    isAddNewServiceSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      integer,
      positive,
      value: null,
      options: [],
    }
  },
  computed: {
    ...mapState(
      'StylistServiceStoreModule', {
        categories: state => state.categories,
        saveServiceStore: state => state.saveService,
      },
    ),
  },
  watch: {
    categories: {
      deep: true,
      immediate: true,
      handler(newDetails) {
        if (newDetails) {
          this.options = newDetails.response
        }
      },
    },
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    ...mapActions('StylistServiceStoreModule', [
      'fetchCategories',
    ]),
  },
  setup(props, { emit }) {
    const blankserviceData = {
      name: null,
      price: null,
      hourly: 0,
      duration: 45,
      public: 0,
      category: null,
    }

    const USER_APP_STORE_MODULE_NAME = 'stylist-service'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, serviceStoreModule)

    const serviceData = ref(JSON.parse(JSON.stringify(blankserviceData)))
    const resetserviceData = () => {
      serviceData.value = JSON.parse(JSON.stringify(blankserviceData))
    }

    const onSubmit = () => {
      store.dispatch('stylist-service/addService', {
        name: serviceData.value.name,
        price: serviceData.value.price,
        hourly: Boolean(serviceData.value.hourly),
        duration: Number(serviceData.value.duration) === 0 ? 45 : Number(serviceData.value.duration),
        category: serviceData.value.category,
        public: Boolean(serviceData.value.public),
      })
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-service-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetserviceData)

    return {
      serviceData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
