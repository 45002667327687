import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useStylistServiceList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'price', sortable: true },
    { key: 'hourly', sortable: true },
    { key: 'duration', sortable: true },
    { key: 'public', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  let totalItems = 0
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchStylistServices = (ctx, callback) => {
    store
      .dispatch('stylist-service/fetchStylistServices', {
        itemsPerPage: perPage.value,
        page: currentPage.value,
        [`order[${sortBy.value}]`]: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        totalItems = response.data['hydra:totalItems']
        callback(response.data['hydra:member'])
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching services list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCategories = (ctx, callback) => {
    store
      .dispatch('stylist-service/fetchCategories', {
        itemsPerPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        totalItems = response.data
        callback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteService = serviceId => {
    store.dispatch('stylist-service/deleteService', serviceId)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Service has been deleted',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting service',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    fetchStylistServices,
    fetchCategories,
    deleteService,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,
  }
}
