import { $themeBreakpoints } from '@themeConfig'
import { parseDomain, ParseResultType } from 'parse-domain'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getSubdomain() {
      const parseDomainResult = parseDomain(window.location.hostname)
      let currentSubdomain = null

      if (parseDomainResult.type === ParseResultType.Listed) {
        const { subDomains } = parseDomainResult
        currentSubdomain = typeof subDomains[0] !== 'undefined' && subDomains[0] !== 'www' ? subDomains[0] : null
      }

      return currentSubdomain
    },
    getDomain() {
      const parseDomainResult = parseDomain(window.location.hostname)
      const currentPort = typeof window.location.port !== 'undefined' ? `:${window.location.port}` : null
      let currentDomain = null
      if (parseDomainResult.type === ParseResultType.Listed) {
        const { domain, topLevelDomains } = parseDomainResult
        currentDomain = `${domain}.${topLevelDomains}${currentPort}`
      }
      return currentDomain
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
}
