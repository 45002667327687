import {
  LOGGED_CUSTOMER_APPOINTMENT_API_ENDPOINT,
  LOGGED_CUSTOMER_APPOINTMENTS_API_ENDPOINT,
  LOGGED_CUSTOMER_BLOCK_SELECTED_DATE_TIME_API_ENDPOINT,
  LOGGED_CUSTOMER_CUSTOMER_API_ENDPOINT,
  LOGGED_CUSTOMER_PAYMENT_ADD_CARD_CUSTOMER,
  LOGGED_CUSTOMER_PAYMENT_CREATE_CUSTOMER,
  PUBLIC_SERVICES_API_ENDPOINT,
  LOGGED_CUSTOMER_STYLIST_POLICIES_API_ENDPOINT,
  LOGGED_CUSTOMER_PAYMENT_CUSTOMER_CARDS_LIST,
  LOGGED_CUSTOMER_APPOINTMENT_BY_UUID_ENDPOINT,
  LOGGED_CUSTOMER_APPOINTMENT_CONFIRM,
  LOGGED_CUSTOMER_SERVICES_REQUEST_CONSULTATION,
} from '@/api/apiUrl'
import { apiInstance as api } from '@/api/plugin'
import { queryGet } from '@/api/apiConfig'

export default {
  namespaced: true,
  state: {
    availableDates: {
      response: null,
      isPending: false,
      error: null,
    },
    blockDateTime: {
      response: null,
      isPending: false,
      error: null,
    },
    sendServiceRequestConsultation: {
      response: null,
      isPending: false,
      error: null,
    },
    saveAppointment: {
      response: {
        customer: {
          name: null,
          lastName: null,
          email: null,
          phone: null,
        },
        services: [],
        startsAt: null,
        endsAt: null,
        amount: null,
        note: null,
      },
      isPending: false,
      error: null,
    },
    appointment: {
      response: null,
      isPending: false,
      error: null,
    },
    appointments: {
      response: null,
      isPending: false,
      error: null,
    },
    cancelAppointment: {
      response: {},
      isPending: false,
      error: null,
    },
    stylistPolicies: {
      response: [],
      isPending: false,
      error: null,
    },
    publicServices: {
      response: [],
      isPending: false,
      error: null,
    },
    selectedDateTime: null,
    selectedServices: null,
    customer: null,
    customerLogged: {
      isPending: false,
      response: null,
      error: null,
    },
    confirmAppointment: {
      isPending: false,
      response: null,
      error: null,
    },
    createCard: {
      isPending: false,
      response: null,
      error: null,
    },
    cardsList: {
      isPending: false,
      response: null,
      error: null,
    },
  },
  getters: {
    stylistPoliciesGetter: state => {
      const stylistPolicies = state.stylistPolicies.response.reduce((obj, item) => ({
        ...obj,
        [item.stylistPolicyConfigDictionary]: item,
      }), {})
      return stylistPolicies
    },
    serviceDataGetter: state => serviceId => {
      const publicServices = state.publicServices.response.reduce((obj, item) => ({
        ...obj,
        [item.id]: item,
      }), {})
      return publicServices[serviceId]
    },
  },
  mutations: {
    FETCH_AVAILABLE_DATES_RECEIVED(state, response) {
      state.availableDates.isPending = false
      state.availableDates.response = response.data
      state.availableDates.error = null
    },
    FETCH_AVAILABLE_DATES_PENDING(state) {
      state.availableDates.isPending = true
      state.availableDates.response = null
      state.availableDates.error = null
    },
    FETCH_AVAILABLE_DATES_ERROR(state, error) {
      state.availableDates.isPending = false
      state.availableDates.response = null
      state.availableDates.error = error
    },
    FETCH_PUBLIC_SERVICES_RECEIVED(state, response) {
      state.publicServices.isPending = false
      state.publicServices.response = response.data
      state.publicServices.error = null
    },
    FETCH_PUBLIC_SERVICES_PENDING(state) {
      state.publicServices.isPending = true
      state.publicServices.response = null
      state.publicServices.error = null
    },
    FETCH_PUBLIC_SERVICES_ERROR(state, error) {
      state.publicServices.isPending = false
      state.publicServices.response = null
      state.publicServices.error = error
    },
    FETCH_CUSTOMER_LOGGED_DATA_RECEIVED(state, response) {
      state.customerLogged.isPending = false
      state.customerLogged.response = response.data
      state.customerLogged.error = null
    },
    FETCH_CUSTOMER_LOGGED_DATA_PENDING(state) {
      state.customerLogged.isPending = true
      state.customerLogged.response = null
      state.customerLogged.error = null
    },
    FETCH_CUSTOMER_LOGGED_DATA_ERROR(state, error) {
      state.customerLogged.isPending = false
      state.customerLogged.response = null
      state.customerLogged.error = error
    },
    FETCH_STYLIST_POLICIES_RECEIVED(state, response) {
      state.stylistPolicies.isPending = false
      state.stylistPolicies.response = response.data
      state.stylistPolicies.error = null
    },
    FETCH_STYLIST_POLICIES_PENDING(state) {
      state.stylistPolicies.isPending = true
      state.stylistPolicies.response = null
      state.stylistPolicies.error = null
    },
    FETCH_STYLIST_POLICIES_ERROR(state, error) {
      state.stylistPolicies.isPending = false
      state.stylistPolicies.response = null
      state.stylistPolicies.error = error
    },
    BLOCK_DATE_TIME_RECEIVED(state, response) {
      state.blockDateTime.isPending = false
      state.blockDateTime.response = response.data
      state.blockDateTime.error = null
    },
    BLOCK_DATE_TIME_PENDING(state) {
      state.blockDateTime.isPending = true
      state.blockDateTime.response = null
      state.blockDateTime.error = null
    },
    BLOCK_DATE_TIME_ERROR(state, error) {
      state.blockDateTime.isPending = false
      state.blockDateTime.response = null
      state.blockDateTime.error = error
    },
    SAVE_APPOINTMENT_RECEIVED(state, response) {
      state.saveAppointment.isPending = false
      state.saveAppointment.response = response.data
      state.saveAppointment.error = null
    },
    SAVE_APPOINTMENT_PENDING(state) {
      state.saveAppointment.isPending = true
      state.saveAppointment.response = null
      state.saveAppointment.error = null
    },
    SAVE_APPOINTMENT_ERROR(state, error) {
      state.saveAppointment.isPending = false
      state.saveAppointment.response = null
      state.saveAppointment.error = error
    },
    ADD_CARD_TO_CUSTOMER_RECEIVED(state, response) {
      state.createCard.isPending = false
      state.createCard.response = response.data
      state.createCard.error = null
    },
    ADD_CARD_TO_CUSTOMER_PENDING(state) {
      state.createCard.isPending = true
      state.createCard.response = null
      state.createCard.error = null
    },
    ADD_CARD_TO_CUSTOMER_ERROR(state, error) {
      state.createCard.isPending = false
      state.createCard.response = null
      state.createCard.error = error
    },
    FETCH_CUSTOMER_CARDS_LIST_RECEIVED(state, response) {
      state.cardsList.isPending = false
      state.cardsList.response = response.data
      state.cardsList.error = null
    },
    FETCH_CUSTOMER_CARDS_LIST_PENDING(state) {
      state.cardsList.isPending = true
      state.cardsList.response = null
      state.cardsList.error = null
    },
    FETCH_CUSTOMER_CARDS_LIST_ERROR(state, error) {
      state.cardsList.isPending = false
      state.cardsList.response = null
      state.cardsList.error = error
    },
    CREATE_STRIPE_CUSTOMER_RECEIVED(state, response) {
      state.customerLogged.isPending = false
      state.customerLogged.response = response.data
      state.customerLogged.error = null
    },
    CREATE_STRIPE_CUSTOMER_PENDING(state) {
      state.customerLogged.isPending = true
      state.customerLogged.response = null
      state.customerLogged.error = null
    },
    CREATE_STRIPE_CUSTOMER_ERROR(state, error) {
      state.customerLogged.isPending = false
      state.customerLogged.response = null
      state.customerLogged.error = error
    },
    CONFIRM_APPOINTMENT_RECEIVED(state, response) {
      state.confirmAppointment.isPending = false
      state.confirmAppointment.response = response.data
      state.saveAppointment.response = response.data
      state.confirmAppointment.error = null
    },
    CONFIRM_APPOINTMENT_PENDING(state) {
      state.confirmAppointment.isPending = true
      state.confirmAppointment.response = null
      state.saveAppointment.response = null
      state.confirmAppointment.error = null
    },
    CONFIRM_APPOINTMENT_ERROR(state, error) {
      state.confirmAppointment.isPending = false
      state.confirmAppointment.response = null
      state.saveAppointment.response = null
      state.confirmAppointment.error = error
    },
    FETCH_APPOINTMENTS_RECEIVED(state, response) {
      state.appointments.isPending = false
      state.appointments.response = response.data
      state.appointments.error = null
    },
    FETCH_APPOINTMENTS_PENDING(state) {
      state.appointments.isPending = true
      state.appointments.error = null
    },
    FETCH_APPOINTMENTS_ERROR(state, error) {
      state.appointments.isPending = false
      state.appointments.response = []
      state.appointments.error = error
    },
    FETCH_APPOINTMENT_RECEIVED(state, response) {
      state.appointment.isPending = false
      state.appointment.response = response.data
      state.appointment.error = null
    },
    FETCH_APPOINTMENT_PENDING(state) {
      state.appointment.isPending = true
      state.appointment.error = null
    },
    FETCH_APPOINTMENT_ERROR(state, error) {
      state.appointment.isPending = false
      state.appointment.response = null
      state.appointment.error = error
    },
    CANCEL_APPOINTMENT_RECEIVED(state, response) {
      state.cancelAppointment.isPending = false
      state.cancelAppointment.response = response.data
      state.cancelAppointment.error = null
    },
    CANCEL_APPOINTMENT_PENDING(state) {
      state.cancelAppointment.isPending = true
      state.cancelAppointment.response = null
      state.cancelAppointment.error = null
    },
    CANCEL_APPOINTMENT_ERROR(state, error) {
      state.cancelAppointment.isPending = false
      state.cancelAppointment.response = null
      state.cancelAppointment.error = error
    },
    SEND_SERVICE_REQUEST_CONSULTATION_RECEIVED(state) {
      state.sendServiceRequestConsultation.isPending = false
      state.sendServiceRequestConsultation.response = true
      state.sendServiceRequestConsultation.error = null
    },
    SEND_SERVICE_REQUEST_CONSULTATION_PENDING(state) {
      state.sendServiceRequestConsultation.isPending = true
      state.sendServiceRequestConsultation.response = null
      state.sendServiceRequestConsultation.error = null
    },
    SEND_SERVICE_REQUEST_CONSULTATION_ERROR(state, error) {
      state.sendServiceRequestConsultation.isPending = false
      state.sendServiceRequestConsultation.response = null
      state.sendServiceRequestConsultation.error = error
    },
    SET_SELECTED_DATE_TIME(state, selectedDateTime) {
      state.selectedDateTime = selectedDateTime
    },
    CLEAR_SELECTED_DATE_TIME(state) {
      state.selectedDateTime = null
    },
    SET_SELECTED_SERVICES(state, selectedServices) {
      state.selectedServices = selectedServices
    },
    CLEAR_SELECTED_SERVICES(state) {
      state.selectedServices = null
    },
    SET_CREATE_CARD_PENDING(state, value) {
      state.createCard.isPending = value
    },
  },
  actions: {
    fetchAvailableDates({ commit }, { duration, period, apiToken }) {
      commit('FETCH_AVAILABLE_DATES_PENDING')
      return new Promise(() => {
        queryGet(`${PUBLIC_SERVICES_API_ENDPOINT}/duration/${duration}/available-dates/period/${period.startDate}/${period.endDate}`, apiToken)
          .then(response => {
            commit('FETCH_AVAILABLE_DATES_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_AVAILABLE_DATES_ERROR', error)
          })
      })
    },
    fetchStylistPublicServices({ commit }) {
      commit('FETCH_PUBLIC_SERVICES_PENDING')
      return new Promise(() => {
        queryGet(PUBLIC_SERVICES_API_ENDPOINT)
          .then(response => {
            commit('FETCH_PUBLIC_SERVICES_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_PUBLIC_SERVICES_ERROR', error)
          })
      })
    },
    fetchCustomerData({ commit }) {
      commit('FETCH_CUSTOMER_LOGGED_DATA_PENDING')
      return new Promise(() => {
        api.get(`${LOGGED_CUSTOMER_CUSTOMER_API_ENDPOINT}`)
          .then(response => {
            commit('FETCH_CUSTOMER_LOGGED_DATA_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_CUSTOMER_LOGGED_DATA_ERROR', error)
          })
      })
    },
    fetchStylistPolicies({ commit }) {
      commit('FETCH_STYLIST_POLICIES_PENDING')
      return new Promise(() => {
        api.get(`${LOGGED_CUSTOMER_STYLIST_POLICIES_API_ENDPOINT}`)
          .then(response => {
            commit('FETCH_STYLIST_POLICIES_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_STYLIST_POLICIES_ERROR', error)
          })
      })
    },
    blockSelectedDateTime({ commit }, period) {
      commit('BLOCK_DATE_TIME_PENDING')
      return new Promise(() => {
        api.post(`${LOGGED_CUSTOMER_BLOCK_SELECTED_DATE_TIME_API_ENDPOINT}`, period)
          .then(response => {
            commit('BLOCK_DATE_TIME_RECEIVED', response)
          })
          .catch(error => {
            commit('BLOCK_DATE_TIME_ERROR', error)
          })
      })
    },
    saveAppointment({ commit, dispatch }, appointment) {
      commit('SAVE_APPOINTMENT_PENDING')
      return new Promise(() => {
        api.post(`${LOGGED_CUSTOMER_APPOINTMENT_API_ENDPOINT}`, appointment)
          .then(response => {
            commit('SAVE_APPOINTMENT_RECEIVED', response)
            dispatch('fetchAppointments')
          })
          .catch(error => {
            commit('SAVE_APPOINTMENT_ERROR', error)
          })
      })
    },
    createStripeCustomer({ commit }, customer) {
      commit('CREATE_STRIPE_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.post(`${LOGGED_CUSTOMER_PAYMENT_CREATE_CUSTOMER}`, customer)
          .then(response => {
            commit('CREATE_STRIPE_CUSTOMER_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('CREATE_STRIPE_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
    addCardToCustomer({ commit }, token) {
      commit('ADD_CARD_TO_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.post(`${LOGGED_CUSTOMER_PAYMENT_ADD_CARD_CUSTOMER}`, {
          cardToken: token,
        })
          .then(response => {
            commit('ADD_CARD_TO_CUSTOMER_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('ADD_CARD_TO_CUSTOMER_ERROR', error)
            reject(error)
          })
      }).catch(error => {
        commit('ADD_CARD_TO_CUSTOMER_ERROR', error)
      })
    },
    fetchAppointments({ commit }) {
      commit('FETCH_APPOINTMENTS_PENDING')
      return new Promise(() => {
        api.get(`${LOGGED_CUSTOMER_APPOINTMENTS_API_ENDPOINT}`)
          .then(response => {
            commit('FETCH_APPOINTMENTS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENTS_ERROR', error)
          })
      })
    },
    fetchAppointmentByUuid({ commit }, uuid) {
      commit('FETCH_APPOINTMENT_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`${LOGGED_CUSTOMER_APPOINTMENT_BY_UUID_ENDPOINT}/${uuid}`)
          .then(response => {
            commit('FETCH_APPOINTMENT_RECEIVED', response)
            resolve(response.data)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENT_ERROR', error)
            reject(error)
          })
      })
    },
    cancelAppointment({ commit, dispatch }, appointment) {
      commit('CANCEL_APPOINTMENT_PENDING')
      return new Promise((resolve, reject) => {
        api.delete(`${LOGGED_CUSTOMER_APPOINTMENT_API_ENDPOINT}?appointmentId=${appointment.id}`)
          .then(response => {
            commit('CANCEL_APPOINTMENT_RECEIVED', response)
            dispatch('fetchAppointments')
            resolve(response)
          })
          .catch(error => {
            commit('CANCEL_APPOINTMENT_ERROR', error)
            reject(error)
          })
      })
    },
    fetchCustomerCardsList({ commit }) {
      commit('FETCH_CUSTOMER_CARDS_LIST_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`${LOGGED_CUSTOMER_PAYMENT_CUSTOMER_CARDS_LIST}`)
          .then(response => {
            commit('FETCH_CUSTOMER_CARDS_LIST_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_CUSTOMER_CARDS_LIST_ERROR', error)
            reject(error)
          })
      })
    },
    confirmAppointment({ commit }, {
      appointmentId, stripeCardId, customer, note,
    }) {
      commit('CONFIRM_APPOINTMENT_PENDING')
      return new Promise((resolve, reject) => {
        api.post(LOGGED_CUSTOMER_APPOINTMENT_CONFIRM, {
          appointmentId, stripeCardId, customer, note,
        })
          .then(response => {
            commit('CONFIRM_APPOINTMENT_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('CONFIRM_APPOINTMENT_ERROR', error)
            reject(error)
          })
      })
    },
    sendServiceRequestConsultation({ commit }, data) {
      commit('SEND_SERVICE_REQUEST_CONSULTATION_PENDING')
      return new Promise((resolve, reject) => {
        api.post(LOGGED_CUSTOMER_SERVICES_REQUEST_CONSULTATION, data)
          .then(response => {
            commit('SEND_SERVICE_REQUEST_CONSULTATION_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('SEND_SERVICE_REQUEST_CONSULTATION_ERROR', error)
            reject(error)
          })
      })
    },
    setSelectedDateTime({ commit }, dateTime) {
      commit('SET_SELECTED_DATE_TIME', dateTime)
    },
    clearSelectedDateTime({ commit }) {
      commit('CLEAR_SELECTED_DATE_TIME')
    },
    setSelectedServices({ commit }, services) {
      commit('SET_SELECTED_SERVICES', services)
    },
    clearSelectedServices({ commit }) {
      commit('CLEAR_SELECTED_SERVICES')
    },
  },
}
