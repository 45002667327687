import { queryGet } from '@/api/apiConfig'

export default {
  namespaced: true,
  state: {
    subdomain: {
      response: null,
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    CLEAR_SUBDOMAIN_STATUS_ERROR(state) {
      state.subdomain.isPending = false
      state.subdomain.response = null
      state.subdomain.error = null
    },
    FETCH_SUBDOMAIN_STATUS_RECEIVED(state, response) {
      state.subdomain.isPending = false
      state.subdomain.response = response.data
      state.subdomain.error = null
    },
    FETCH_SUBDOMAIN_STATUS_PENDING(state) {
      state.subdomain.isPending = true
      state.subdomain.response = null
      state.subdomain.error = null
    },
    FETCH_SUBDOMAIN_STATUS_ERROR(state, error) {
      state.subdomain.isPending = false
      state.subdomain.response = null
      state.subdomain.error = error
    },
  },
  actions: {
    fetchSubdomainStatus({ commit }, subdomain) {
      commit('FETCH_SUBDOMAIN_STATUS_PENDING')
      return new Promise(() => {
        queryGet(`/api/public/stylists/subdomain/${subdomain}/check`)
          .then(response => {
            commit('FETCH_SUBDOMAIN_STATUS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_SUBDOMAIN_STATUS_ERROR', error)
          })
      })
    },
  },
}
