<template>
  <b-overlay
    :show="fetching"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-card-code title="Open hours">
      <div>
        <validation-observer ref="simpleRules">

          <b-form
            id="save-open-hours-form"
            ref="form"
            slot-scope="{ validate }"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="validationForm(validate())"
          >
            <div
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
            >
              <!-- Row Loop -->
              <b-row ref="row">

                <!-- Weekday -->
                <b-col md="4">
                  <b-form-group
                    label="Day of the week"
                    label-for="week-day"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :vid="`week-day${item.id}`"
                      name="Day of the week"
                      rules="required"
                    >
                      <b-form-select
                        :id="`week-day${item.id}`"
                        v-model="item.weekDay"
                        :options="options"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Time from -->
                <b-col md="2">
                  <b-form-group
                    label="From"
                    label-for="time-from"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`timeFrom${item.id}`"
                      :rules="`timetest:@timeTo${item.id}`"
                    >
                      <b-form-select
                        :id="`timetest:@timeTo${item.id}`"
                        v-model="item.startTime"
                        :options="getAvailableHours()"
                        :state="errors[0] ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Time to -->
                <b-col md="2">
                  <b-form-group
                    label="To"
                    label-for="time-to"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`timeTo${item.id}`"
                      :vid="`timeTo${item.id}`"
                    >
                      <b-form-select
                        v-model="item.endTime"
                        :name="`timeTo${item.id}`"
                        :options="getAvailableHours()"
                        :state="errors[0] ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col
                  lg="2"
                  md="3"
                  class="mb-50"
                >
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
      <b-col
        cols="12"
        class="pl-0 mt-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          type="submit"
          :disabled="saving"
          form="save-open-hours-form"
        >
          Save changes
        </b-button>
        <div
          v-if="saving"
          class="mb-1 d-inline"
        >
          <b-spinner small />
        </div>
      </b-col>
    </b-card-code>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import store from '@/store'
import BCardCode from '@core/components/b-card-code'
import {
  BForm, BFormGroup, BRow, BCol, BButton, BFormSelect, BSpinner, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

extend('timetest', {
  params: ['target'],
  validate(value, { target }) {
    return value < target
  },
  message: 'Time from can\'t be later or equal to time to',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BSpinner,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      defaultState: {
        id: 1,
        weekDay: null,
        startTime: '08:00',
        endTime: '18:00',
        prevHeight: 0,
      },
      required,
      items: [],
      selected: null,
      options: [
        { value: null, text: 'Please select' },
        { value: '6', text: 'Sunday' },
        { value: '0', text: 'Monday' },
        { value: '1', text: 'Tuesday' },
        { value: '2', text: 'Wednesday' },
        { value: '3', text: 'Thursday' },
        { value: '4', text: 'Friday' },
        { value: '5', text: 'Saturday' },
      ],
      nextTodoId: 2,
      fetching: true,
      saving: false,
    }
  },
  mounted() {
    this.initTrHeight()
    this.fetchOpenHoursLocal()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    validationForm(validate) {
      validate.then(success => {
        if (success) {
          this.saveChanges()
        }
      })
    },
    fetchOpenHoursLocal() {
      store.dispatch('StylistSettingStoreModule/fetchOpenHours')
        .then(response => {
          this.fetching = false
          response.data.forEach(openHour => {
            this.repeater(openHour)
          })
        })
        .catch(error => {
          this.fetching = false
          if (error.response.status === 404) {
            // console.log
          }
        })
    },
    repeater(obj) {
      this.items.push({
        ...obj, id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    repeateAgain() {
      this.repeater(this.defaultState)
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    getAvailableHours() {
      const ret = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = 24; i < len; i++) {
        const val = `${String(i).padStart(2, 0)}:00`
        // eslint-disable-next-line no-nested-ternary,eqeqeq
        const tex = i == 12 ? `${String(i).padStart(2, 0)}:00 PM` : i < 12 ? `${String(i).padStart(2, 0)}:00 AM` : `${String(i - 12).padStart(2, 0)}:00 PM`
        ret.push({ value: val, text: tex })
      }
      return ret
    },
    saveChanges() {
      this.saving = true
      store.dispatch('StylistSettingStoreModule/saveOpenHours', this.items)
        .then(() => {
          this.saving = false
          this.success()
        }).catch(() => {
          this.saving = false
          this.error()
        })
    },
    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Data has been updated',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    error() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Data has not been updated',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
  height: 100% !important;
}
</style>
