import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    saveData: {
      response: false,
      isPending: false,
      error: false,
    },
    timeZonesDictionary: {
      response: false,
      isPending: false,
      error: false,
    },
    saveStylistPolicy: {
      response: false,
      isPending: false,
      error: false,
    },
    stylistPolicy: {
      response: false,
      isPending: false,
      error: false,
    },
    connectStripeAccount: {
      response: false,
      isPending: false,
      error: false,
    },
    stripeAccount: {
      response: false,
      isPending: false,
      error: false,
    },
    activeTab: 0,
  },
  getters: {},
  mutations: {
    CONNECT_STRIPE_ACCOUNT_PENDING(state) {
      state.connectStripeAccount.isPending = true
      state.connectStripeAccount.response = false
      state.connectStripeAccount.error = false
    },
    CONNECT_STRIPE_ACCOUNT_ERROR(state, error) {
      state.connectStripeAccount.isPending = false
      state.connectStripeAccount.response = false
      state.connectStripeAccount.error = error
    },
    CONNECT_STRIPE_ACCOUNT_RECEIVED(state, response) {
      state.connectStripeAccount.isPending = false
      state.connectStripeAccount.response = response.data
      state.connectStripeAccount.error = false
    },
    FETCH_STRIPE_ACCOUNT_PENDING(state) {
      state.stripeAccount.isPending = true
      state.stripeAccount.response = false
      state.stripeAccount.error = false
    },
    FETCH_STRIPE_ACCOUNT_ERROR(state, error) {
      state.stripeAccount.isPending = false
      state.stripeAccount.response = false
      state.stripeAccount.error = error
    },
    FETCH_STRIPE_ACCOUNT_RECEIVED(state, response) {
      state.stripeAccount.isPending = false
      state.stripeAccount.response = response.data
      state.stripeAccount.error = false
    },
    SAVE_OPEN_HOURS_FULFIL(state) {
      state.saveData.isPending = false
      state.saveData.response = true
      state.saveData.error = false
    },
    SAVE_OPEN_HOURS_PENDING(state) {
      state.saveData.isPending = true
      state.saveData.response = false
      state.saveData.error = false
    },
    SAVE_OPEN_HOURS_ERROR(state, error) {
      state.saveData.isPending = false
      state.saveData.response = false
      state.saveData.error = error
    },
    SAVE_STYLIST_POLICY_RECEIVED(state, response) {
      state.saveStylistPolicy.isPending = false
      state.saveStylistPolicy.response = response.data
      state.saveStylistPolicy.error = false
    },
    SAVE_STYLIST_POLICY_PENDING(state) {
      state.saveStylistPolicy.isPending = true
      state.saveStylistPolicy.response = false
      state.saveStylistPolicy.error = false
    },
    SAVE_STYLIST_POLICY_ERROR(state, error) {
      state.saveStylistPolicy.isPending = false
      state.saveStylistPolicy.response = false
      state.saveStylistPolicy.error = error
    },
    FETCH_STYLIST_POLICY_RECEIVED(state, response) {
      state.stylistPolicy.isPending = false
      state.stylistPolicy.response = response.data
      state.stylistPolicy.error = false
    },
    FETCH_STYLIST_POLICY_PENDING(state) {
      state.stylistPolicy.isPending = true
      state.stylistPolicy.response = false
      state.stylistPolicy.error = false
    },
    FETCH_STYLIST_POLICY_ERROR(state, error) {
      state.stylistPolicy.isPending = false
      state.stylistPolicy.response = false
      state.stylistPolicy.error = error
    },
    FETCH_TIME_ZONE_DICTIONARY_RECEIVED(state, response) {
      state.timeZonesDictionary.isPending = false
      state.timeZonesDictionary.response = response.data
      state.timeZonesDictionary.error = false
    },
    FETCH_TIME_ZONE_DICTIONARY_PENDING(state) {
      state.timeZonesDictionary.isPending = true
      state.timeZonesDictionary.response = null
      state.timeZonesDictionary.error = false
    },
    FETCH_TIME_ZONE_DICTIONARY_ERROR(state, error) {
      state.timeZonesDictionary.isPending = false
      state.timeZonesDictionary.response = null
      state.timeZonesDictionary.error = error
    },
    SET_ACTIVE_TAB(state, tabNumber) {
      state.activeTab = tabNumber
    },
  },
  actions: {
    fetchOpenHours() {
      return new Promise((resolve, reject) => {
        api.get('/api/open_hours', {
          params: {
            'order[weekDay]': 'ASC',
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    connectStripeAccount({ commit }) {
      commit('CONNECT_STRIPE_ACCOUNT_PENDING')
      return new Promise((resolve, reject) => {
        api.post('/api/stylist/stripe/onboarding')
          .then(response => {
            commit('CONNECT_STRIPE_ACCOUNT_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('CONNECT_STRIPE_ACCOUNT_ERROR', error)
            reject(error)
          })
      })
    },
    fetchStripeAccount({ commit }) {
      commit('FETCH_STRIPE_ACCOUNT_PENDING')
      return new Promise(() => {
        api.get('/api/stylist/stripe/account')
          .then(response => {
            commit('FETCH_STRIPE_ACCOUNT_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_STRIPE_ACCOUNT_ERROR', error)
          })
      })
    },
    saveOpenHours({ commit }, openHoursData) {
      commit('SAVE_OPEN_HOURS_PENDING')
      return new Promise((resolve, reject) => {
        api.post('/api/open_hours', openHoursData)
          .then(response => {
            commit('SAVE_OPEN_HOURS_FULFIL')
            resolve(response)
          })
          .catch(error => {
            commit('SAVE_OPEN_HOURS_ERROR', error)
            reject(error)
          })
      })
    },
    saveStylistPolicy({ commit }, stylistPolicyData) {
      commit('SAVE_STYLIST_POLICY_PENDING')
      return new Promise(() => {
        api.post('/api/stylist-policy-config', stylistPolicyData)
          .then(response => {
            commit('SAVE_STYLIST_POLICY_RECEIVED', response)
          })
          .catch(error => {
            commit('SAVE_STYLIST_POLICY_ERROR', error)
          })
      })
    },
    fetchStylistPolicy({ commit }) {
      commit('FETCH_STYLIST_POLICY_PENDING')
      return new Promise(() => {
        api.get('/api/stylist-policy-config')
          .then(response => {
            commit('FETCH_STYLIST_POLICY_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_STYLIST_POLICY_ERROR', error)
          })
      })
    },
    fetchTimeZonesDictionary({ commit }) {
      commit('FETCH_TIME_ZONE_DICTIONARY_PENDING')
      return new Promise(() => {
        api.get('/api/time_zone_dictionaries')
          .then(response => {
            commit('FETCH_TIME_ZONE_DICTIONARY_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_TIME_ZONE_DICTIONARY_ERROR', error)
          })
      })
    },
    setActiveTab({ commit }, tabNumber) {
      commit('SET_ACTIVE_TAB', tabNumber)
    },
  },
}
