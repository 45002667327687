<template>
  <b-overlay
    :show="servicesStore.isPending"
    bg-color="#ffffff"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <stylist-service-list-add
      :is-add-new-service-sidebar-active.sync="isAddNewServiceSidebarActive"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewServiceSidebarActive = true"
              >
                <span class="text-nowrap">Add Service</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchStylistServices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(price)="data">
          ${{ data.value }}
        </template>

        <template #cell(hourly)="data">
          {{ data.value ? 'yes' : 'no' }}
        </template>

        <template #cell(duration)="data">
          {{ data.value }} min
        </template>

        <template #cell(public)="data">
          {{ data.value ? 'yes' : 'no' }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button-group>

            <b-button
              variant="outline-secondary"
              class="mt-0"
              size="sm"
              :to="{ name: 'service-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span>Edit</span>
            </b-button>

            <b-button
              variant="outline-danger"
              class="mt-0"
              size="sm"
              @click="deleteServiceModal(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span>Delete</span>
            </b-button>
          </b-button-group>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.of"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-modal
      v-model="modalShow"
      title="Delete service"
      class="modal modal-danger"
      ok-title="Confirm"
      @ok="deleteService(service.id)"
    >
      <span>Confirm if you want to delete the service:</span>
      <b-row class="customer-form">
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            label="name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="service.name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            label="Price ($)"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="service.price"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            label="Duration (m)"
            label-for="duration"
          >
            <b-form-input
              id="duration"
              v-model="service.duration"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BButtonGroup,
  BTable, BPagination, BFormInput, BFormGroup,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { mapState } from 'vuex'
import useStylistServiceList from './useStylistServiceList'
import stylistServiceStoreModule from './StylistServiceStoreModule'
import StylistServiceListAdd from './StylistServiceListAdd.vue'

export default {
  components: {
    StylistServiceListAdd,
    BCard,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BTable,
    BPagination,
    BFormInput,
    BFormGroup,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      modalShow: false,
      service: {
        id: null,
        name: null,
        price: null,
        duration: null,
        public: null,
      },
    }
  },
  computed: {
    ...mapState('StylistServiceStoreModule', {
      servicesStore: state => state.services,
    }),
  },
  methods: {
    deleteServiceModal(service) {
      this.modalShow = !this.modalShow
      this.service = { ...service }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'stylist-service'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, stylistServiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewServiceSidebarActive = ref(false)

    const {
      fetchStylistServices,
      deleteService,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

    } = useStylistServiceList()

    return {

      // Sidebar
      isAddNewServiceSidebarActive,

      fetchStylistServices,
      deleteService,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
