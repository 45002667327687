import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    service: {
      response: [],
      isPending: false,
      error: null,
    },
    services: {
      response: [],
      isPending: false,
      error: null,
    },
    categories: {
      response: [],
      isPending: false,
      error: null,
    },
    saveService: {
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_CATEGORIES_RECEIVED(state, response) {
      state.categories.isPending = false
      state.categories.response = response.data
      state.categories.error = null
    },
    FETCH_CATEGORIES_PENDING(state) {
      state.categories.isPending = true
      state.categories.response = []
      state.categories.error = null
    },
    FETCH_CATEGORIES_ERROR(state, error) {
      state.categories.isPending = false
      state.categories.response = []
      state.categories.error = error
    },
    FETCH_SERVICE_RECEIVED(state, response) {
      state.service.isPending = false
      state.service.response = response.data
      state.service.error = null
    },
    FETCH_SERVICE_PENDING(state) {
      state.service.isPending = true
      state.service.response = []
      state.service.error = null
    },
    FETCH_SERVICE_ERROR(state, error) {
      state.service.isPending = false
      state.service.response = []
      state.service.error = error
    },
    FETCH_SERVICES_RECEIVED(state, response) {
      state.services.isPending = false
      state.services.response = response.data
      state.services.error = null
    },
    FETCH_SERVICES_PENDING(state) {
      state.services.isPending = true
      state.services.response = []
      state.services.error = null
    },
    FETCH_SERVICES_ERROR(state, error) {
      state.services.isPending = false
      state.services.response = []
      state.services.error = error
    },
    UPDATE_SERVICE_RECEIVED(state) {
      state.service.isPending = false
      state.service.error = null
    },
    UPDATE_SERVICE_PENDING(state) {
      state.service.isPending = true
      state.service.error = null
    },
    UPDATE_SERVICE_ERROR(state, error) {
      state.service.isPending = false
      state.service.error = error
    },
    DELETE_SERVICE_RECEIVED(state) {
      state.service.isPending = false
      state.service.error = null
    },
    DELETE_SERVICE_PENDING(state) {
      state.service.isPending = true
      state.service.error = null
    },
    DELETE_SERVICE_ERROR(state, error) {
      state.service.isPending = false
      state.service.error = error
    },
    SAVE_SERVICE_RECEIVED(state) {
      state.saveService.isPending = false
      state.saveService.error = null
    },
    SAVE_SERVICE_PENDING(state) {
      state.saveService.isPending = true
      state.saveService.error = null
    },
    SAVE_SERVICE_ERROR(state, error) {
      state.saveService.isPending = false
      state.saveService.error = error
    },
  },
  actions: {
    fetchStylistServices({ commit }, queryParams) {
      commit('FETCH_SERVICES_PENDING')
      return new Promise((resolve, reject) => {
        api.get('/api/services', {
          params: queryParams,
          headers: {
            Accept: 'application/ld+json',
          },
        })
          .then(response => {
            commit('FETCH_SERVICES_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_SERVICES_ERROR', error)
            reject(error)
          })
      })
    },
    fetchCategories({ commit }) {
      commit('FETCH_CATEGORIES_PENDING')
      return new Promise((resolve, reject) => {
        api.get('/api/services/categories')
          .then(response => {
            commit('FETCH_CATEGORIES_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_CATEGORIES_ERROR', error)
            reject(error)
          })
      })
    },
    fetchService({ commit }, { id }) {
      commit('FETCH_SERVICE_PENDING')
      return new Promise((resolve, reject) => {
        api.get(`/api/services/${id}`)
          .then(response => {
            commit('FETCH_SERVICE_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_SERVICE_ERROR', error)
            reject(error)
          })
      })
    },
    addService({ commit }, serviceData) {
      commit('SAVE_SERVICE_PENDING')
      return new Promise((resolve, reject) => {
        api.post('/api/services', serviceData)
          .then(response => {
            commit('SAVE_SERVICE_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('SAVE_SERVICE_ERROR', error)
            reject(error)
          })
      })
    },
    deleteService({ commit }, serviceId) {
      commit('DELETE_SERVICE_PENDING')
      return new Promise((resolve, reject) => {
        api.delete(`/api/services/${serviceId}`)
          .then(response => {
            commit('DELETE_SERVICE_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('DELETE_SERVICE_ERROR', error)
            reject(error)
          })
      })
    },
    updateService({ commit }, serviceData) {
      commit('UPDATE_SERVICE_PENDING')
      return new Promise((resolve, reject) => {
        api.put(`/api/services/${serviceData.id}`, serviceData)
          .then(response => {
            commit('UPDATE_SERVICE_RECEIVED')
            resolve(response)
          })
          .catch(error => {
            commit('UPDATE_SERVICE_ERROR', error)
            reject(error)
          })
      })
    },
  },
}
