import { apiInstance as api } from '@/api/plugin'

import {
  APPOINTMENTS_API_ENDPOINT,
  CUSTOMERS_API_ENDPOINT,
  OPEN_HOURS_API_ENDPOINT,
  SERVICES_API_ENDPOINT,
  APPOINTMENT_PAYMENT_CHECKOUT_API_ENDPOINT,
  APPOINTMENT_CARD_DETAILS_API_ENDPOINT,
  APPOINTMENT_PAYMENT_CANCEL_FEE_API_ENDPOINT,
  APPOINTMENT_PAYMENT_NO_SHOW_FEE_API_ENDPOINT, APPOINTMENT_PAYMENT_FEE_AVAILABILITY_API_ENDPOINT,
} from '@/api/apiUrl'
import cardEnum from '@/dictionaries/cardEnum'

import sprintf from 'sprintf-js'

export default {
  namespaced: true,
  state: {
    appointments: {
      response: [],
      isPending: false,
      error: null,
    },
    updateAppointment: {
      response: null,
      isPending: false,
      error: null,
    },
    appointmentTransitions: {
      appointmentId: null,
      response: [],
      isPending: false,
      error: null,
    },
    period: {
      start: null,
      end: null,
    },
    appointmentModal: {
      show: false,
      details: null,
      isPending: false,
      error: null,
    },
    appointmentContextMenuModal: {
      show: false,
      details: null,
    },
    appointmentCardDetails: {
      card: {
        ...cardEnum,
      },
      isPending: false,
      error: null,
    },
    appointmentStateExtraConfirmToChargeModal: {
      show: false,
      state: null,
    },
    appointmentCheckoutPayment: {
      response: [],
      isPending: false,
      error: null,
    },
    appointmentCheckoutModal: {
      show: false,
    },
    appointmentDelete: {
      id: null,
    },
    customers: {
      response: [],
      isPending: false,
      error: null,
    },
    services: {
      response: [],
      isPending: false,
      error: null,
    },
    newCustomer: {
      response: [],
      isPending: false,
      error: null,
    },
    openHours: {
      response: [],
      isPending: false,
      error: null,
    },
    chargeCustomer: {
      response: [],
      isPending: false,
      error: null,
    },
    feeAvailability: {
      response: null,
      isPending: false,
      error: null,
    },
    stylist: {
      response: {
        id: null,
        name: null,
        lastname: null,
        subdomain: null,
        businessName: null,
        businessDescription: null,
        facebookUrl: null,
        twitterUrl: null,
        instagramUrl: null,
        address: null,
        city: null,
        postCode: null,
        latitude: null,
        longtitude: null,
        timeZone: {
          id: null,
          name: null,
          value: null,
        },
        email: null,
        stripeId: null,
        stripeAccountEnabled: null,
      },
      isPending: false,
      error: null,
    },
  },
  getters: {},
  mutations: {
    FETCH_APPOINTMENTS_RECEIVED(state, response) {
      state.appointments.isPending = false
      state.appointments.response = response.data
      state.appointments.error = null
    },
    FETCH_APPOINTMENTS_PENDING(state) {
      state.appointments.isPending = true
      state.appointments.response = []
      state.appointments.error = null
    },
    FETCH_APPOINTMENTS_ERROR(state, error) {
      state.appointments.isPending = false
      state.appointments.response = []
      state.appointments.error = error
    },
    SAVE_APPOINTMENT_RECEIVED(state, response) {
      state.appointments.isPending = false
      state.appointments.response.push(response.data)
      state.appointmentModal.error = null
    },
    SAVE_APPOINTMENT_PENDING(state) {
      state.appointments.isPending = true
      state.appointmentModal.error = null
    },
    SAVE_APPOINTMENT_ERROR(state, error) {
      state.appointments.isPending = false
      state.appointmentModal.error = error
    },
    UPDATE_APPOINTMENT_RECEIVED(state, response) {
      state.appointments.isPending = false
      state.appointments.response.push(response.data)
      state.appointmentModal.error = null
      state.updateAppointment.response = response.data
      state.updateAppointment.isPending = false
      state.updateAppointment.error = null
    },
    UPDATE_APPOINTMENT_PENDING(state) {
      state.appointments.isPending = true
      state.appointmentModal.error = null
      state.updateAppointment.response = null
      state.updateAppointment.isPending = true
      state.updateAppointment.error = null
    },
    UPDATE_APPOINTMENT_ERROR(state, error) {
      state.appointments.isPending = false
      state.appointmentModal.error = error
      state.updateAppointment.response = null
      state.updateAppointment.isPending = false
      state.updateAppointment.error = error
    },
    DELETE_APPOINTMENT_RECEIVED(state) {
      state.appointments.isPending = false
      state.appointments.response = state.appointments.response.filter(appointment => appointment.id !== state.appointmentModal.details.id)
      state.appointmentDelete.id = state.appointmentModal.details.id
      state.appointmentModal.error = null
    },
    DELETE_APPOINTMENT_PENDING(state) {
      state.appointmentModal.show = false
      state.appointments.isPending = true
      state.appointmentModal.error = null
    },
    DELETE_APPOINTMENT_ERROR(state, error) {
      state.appointments.isPending = false
      state.appointmentModal.error = error
    },
    FETCH_APPOINTMENT_CARD_DETAILS_RECEIVED(state, response) {
      state.appointmentCardDetails.isPending = false
      state.appointmentCardDetails.card = response.data
      state.appointmentCardDetails.error = null
    },
    FETCH_APPOINTMENT_CARD_DETAILS_PENDING(state) {
      state.appointmentCardDetails.isPending = true
      state.appointmentCardDetails.card = { ...cardEnum }
      state.appointmentCardDetails.error = null
    },
    FETCH_APPOINTMENT_CARD_DETAILS_ERROR(state, error) {
      state.appointmentCardDetails.isPending = false
      state.appointmentCardDetails.card = { ...cardEnum }
      state.appointmentCardDetails.error = error
    },
    CHECKOUT_APPOINTMENT_PAYMENT_RECEIVED(state, response) {
      state.appointmentCheckoutPayment.isPending = false
      state.appointmentCheckoutPayment.response = response.data
      state.appointmentCheckoutPayment.error = null
    },
    CHECKOUT_APPOINTMENT_PAYMENT_PENDING(state) {
      state.appointmentCheckoutPayment.isPending = true
      state.appointmentCheckoutPayment.response = null
      state.appointmentCheckoutPayment.error = null
    },
    CHECKOUT_APPOINTMENT_PAYMENT_ERROR(state, error) {
      state.appointmentCheckoutPayment.isPending = false
      state.appointmentCheckoutPayment.response = null
      state.appointmentCheckoutPayment.error = error
    },
    SAVE_SELECTED_PERIOD(state, period) {
      state.period.start = period.start
      state.period.end = period.end
    },
    SHOW_APPOINTMENT_CONTEXT_MENU_MODAL(state, appointmentDetails) {
      state.appointmentContextMenuModal.details = appointmentDetails
      state.appointmentContextMenuModal.show = true
    },
    HIDE_APPOINTMENT_CONTEXT_MENU_MODAL(state) {
      state.appointmentContextMenuModal.details = null
      state.appointmentContextMenuModal.show = false
    },
    SHOW_APPOINTMENT_CHECKOUT_MODAL(state) {
      state.appointmentCheckoutModal.show = true
    },
    HIDE_APPOINTMENT_CHECKOUT_MODAL(state) {
      state.appointmentCheckoutModal.show = false
    },
    SHOW_APPOINTMENT_MODAL(state, appointmentDetailsModal) {
      state.appointmentModal.details = appointmentDetailsModal
      state.appointmentModal.show = true
    },
    HIDE_APPOINTMENT_MODAL(state) {
      state.appointmentModal.details = null
      state.appointmentModal.show = false
    },
    FETCH_CUSTOMERS_RECEIVED(state, response) {
      state.customers.isPending = false
      state.customers.response = response.data
      state.customers.error = null
    },
    FETCH_CUSTOMERS_PENDING(state) {
      state.customers.isPending = true
      state.customers.response = []
      state.customers.error = null
    },
    FETCH_CUSTOMERS_ERROR(state, error) {
      state.customers.isPending = false
      state.customers.response = []
      state.customers.error = error
    },
    FETCH_SERVICES_RECEIVED(state, response) {
      state.services.isPending = false
      state.services.response = response.data
      state.services.error = null
    },
    FETCH_SERVICES_PENDING(state) {
      state.services.isPending = true
      state.services.response = []
      state.services.error = null
    },
    FETCH_SERVICES_ERROR(state, error) {
      state.services.isPending = false
      state.services.response = []
      state.services.error = error
    },
    CLEAR_APPOINTMENT_DELETE(state) {
      state.appointmentDelete.id = null
    },
    SAVE_CUSTOMER_RECEIVED(state, response) {
      state.newCustomer.isPending = false
      state.customers.response.push(response.data)
      state.newCustomer.response = response.data
      state.newCustomer.error = null
    },
    SAVE_CUSTOMER_PENDING(state) {
      state.newCustomer.response = []
      state.newCustomer.isPending = true
      state.newCustomer.error = null
    },
    SAVE_CUSTOMER_ERROR(state, error) {
      state.newCustomer.response = []
      state.newCustomer.isPending = false
      state.newCustomer.error = error
    },
    SAVE_CHARGE_CUSTOMER_RECEIVED(state, response) {
      state.chargeCustomer.isPending = false
      state.chargeCustomer.response = response.data
      state.chargeCustomer.error = null
    },
    SAVE_CHARGE_CUSTOMER_PENDING(state) {
      state.chargeCustomer.response = []
      state.chargeCustomer.isPending = true
      state.chargeCustomer.error = null
    },
    SAVE_CHARGE_CUSTOMER_ERROR(state, error) {
      state.chargeCustomer.response = []
      state.chargeCustomer.isPending = false
      state.chargeCustomer.error = error
    },
    FETCH_CUSTOMER_FEE_AVAILABILITY_PENDING(state) {
      state.feeAvailability.response = null
      state.feeAvailability.isPending = true
      state.feeAvailability.error = null
    },
    FETCH_CUSTOMER_FEE_AVAILABILITY_RECEIVED(state, response) {
      state.feeAvailability.response = response.data
      state.feeAvailability.isPending = false
      state.feeAvailability.error = null
    },
    FETCH_CUSTOMER_FEE_AVAILABILITY_ERROR(state, error) {
      state.feeAvailability.response = null
      state.feeAvailability.isPending = false
      state.feeAvailability.error = error
    },
    CLEAR_APPOINTMENT_MODAL_ERROR(state) {
      state.appointmentModal.error = null
    },
    CLEAR_NEW_CUSTOMER_MODAL_ERROR(state) {
      state.newCustomer.error = null
    },
    FETCH_OPEN_HOURS_RECEIVED(state, response) {
      state.openHours.isPending = false
      state.openHours.response = response.data
      state.openHours.error = null
    },
    FETCH_OPEN_HOURS_PENDING(state) {
      state.openHours.isPending = true
      state.openHours.response = []
      state.openHours.error = null
    },
    FETCH_OPEN_HOURS_ERROR(state, error) {
      state.openHours.isPending = false
      state.openHours.response = []
      state.openHours.error = error
    },
    FETCH_STYLIST_RECEIVED(state, response) {
      state.stylist.isPending = false
      state.stylist.response = response.data
      state.stylist.error = null
    },
    FETCH_STYLIST_PENDING(state) {
      state.stylist.isPending = true
      state.stylist.response = []
      state.stylist.error = null
    },
    FETCH_STYLIST_ERROR(state, error) {
      state.stylist.isPending = false
      state.stylist.response = []
      state.stylist.error = error
    },
    FETCH_APPOINTMENT_TRANSITIONS_PENDING(state, appointmentId) {
      state.appointmentTransitions.appointmentId = appointmentId
      state.appointmentTransitions.isPending = true
      state.appointmentTransitions.response = []
      state.appointmentTransitions.error = null
    },
    FETCH_APPOINTMENT_TRANSITIONS_RECEIVED(state, { appointmentId, response }) {
      state.appointmentTransitions.appointmentId = appointmentId
      state.appointmentTransitions.isPending = false
      state.appointmentTransitions.response = response.data
      state.appointmentTransitions.error = null
    },
    FETCH_APPOINTMENT_TRANSITIONS_ERROR(state, { appointmentId, error }) {
      state.appointmentTransitions.appointmentId = appointmentId
      state.appointmentTransitions.isPending = false
      state.appointmentTransitions.response = []
      state.appointmentTransitions.error = error
    },
    SHOW_APPOINTMENT_STATUS_EXTRA_CONFIRM_TO_CHARGE_MODAL(state, appointmentState) {
      state.appointmentStateExtraConfirmToChargeModal.show = true
      state.appointmentStateExtraConfirmToChargeModal.state = appointmentState
    },
    HIDE_APPOINTMENT_STATUS_EXTRA_CONFIRM_TO_CHARGE_MODAL(state) {
      state.appointmentStateExtraConfirmToChargeModal.show = false
      state.appointmentStateExtraConfirmToChargeModal.state = null
    },
  },
  actions: {
    fetchAppointments({ commit, state }, periodPar) {
      commit('FETCH_APPOINTMENTS_PENDING')
      return new Promise(() => {
        const period = periodPar || {
          startDate: state.period.start,
          endDate: state.period.end,
        }

        api.get(`${APPOINTMENTS_API_ENDPOINT}?startsAt[after]=${period.startDate}&startsAt[strictly_before]=${period.endDate}`)
          .then(response => {
            commit('FETCH_APPOINTMENTS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENTS_ERROR', error)
          })
      })
    },
    saveAppointment({ commit }, appointment) {
      commit('SAVE_APPOINTMENT_PENDING')
      return new Promise(() => {
        api.post(`${APPOINTMENTS_API_ENDPOINT}`, appointment)
          .then(response => {
            commit('SAVE_APPOINTMENT_RECEIVED', response)
          })
          .catch(error => {
            commit('SAVE_APPOINTMENT_ERROR', error)
          })
      })
    },
    updateAppointment({ commit }, appointment) {
      commit('UPDATE_APPOINTMENT_PENDING')
      return new Promise(() => {
        api.put(`${APPOINTMENTS_API_ENDPOINT}/${appointment.id}`, appointment)
          .then(response => {
            commit('UPDATE_APPOINTMENT_RECEIVED', response)
          })
          .catch(error => {
            commit('UPDATE_APPOINTMENT_ERROR', error)
          })
      })
    },
    deleteAppointment({ commit }, appointment) {
      commit('DELETE_APPOINTMENT_PENDING')
      return new Promise(() => {
        api.delete(`${APPOINTMENTS_API_ENDPOINT}/${appointment.id}`)
          .then(response => {
            commit('DELETE_APPOINTMENT_RECEIVED', response)
          })
          .catch(error => {
            commit('DELETE_APPOINTMENT_ERROR', error)
          })
      })
    },
    checkoutAppointmentPayment({ commit }, checkout) {
      commit('CHECKOUT_APPOINTMENT_PAYMENT_PENDING')
      return new Promise((resolve, reject) => {
        api.post(sprintf.sprintf(`${APPOINTMENT_PAYMENT_CHECKOUT_API_ENDPOINT}`, checkout), checkout)
          .then(response => {
            commit('CHECKOUT_APPOINTMENT_PAYMENT_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('CHECKOUT_APPOINTMENT_PAYMENT_ERROR', error.response)
            reject(error)
          })
      })
    },
    fetchAppointmentCardDetails({ commit }, appointment) {
      commit('FETCH_APPOINTMENT_CARD_DETAILS_PENDING')
      return new Promise(() => {
        api.get(sprintf.sprintf(`${APPOINTMENT_CARD_DETAILS_API_ENDPOINT}`, appointment))
          .then(response => {
            commit('FETCH_APPOINTMENT_CARD_DETAILS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENT_CARD_DETAILS_ERROR', error)
          })
      })
    },
    fetchCustomers({ commit }) {
      commit('FETCH_CUSTOMERS_PENDING')
      return new Promise(() => {
        api.get(`${CUSTOMERS_API_ENDPOINT}`, {
          params: {
            deleted: false,
          },
        })
          .then(response => {
            commit('FETCH_CUSTOMERS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_CUSTOMERS_ERROR', error)
          })
      })
    },
    fetchServices({ commit }) {
      commit('FETCH_SERVICES_PENDING')
      return new Promise(() => {
        api.get(`${SERVICES_API_ENDPOINT}`)
          .then(response => {
            commit('FETCH_SERVICES_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_SERVICES_ERROR', error)
          })
      })
    },
    fetchOpenHours({ commit }) {
      commit('FETCH_OPEN_HOURS_PENDING')
      return new Promise(() => {
        api.get(`${OPEN_HOURS_API_ENDPOINT}`)
          .then(response => {
            commit('FETCH_OPEN_HOURS_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_OPEN_HOURS_ERROR', error)
          })
      })
    },
    saveCustomer({ commit }, customerData) {
      commit('SAVE_CUSTOMER_PENDING')
      return new Promise(() => {
        api.post('/api/customers', customerData)
          .then(response => {
            commit('SAVE_CUSTOMER_RECEIVED', response)
          })
          .catch(error => {
            commit('SAVE_CUSTOMER_ERROR', error)
          })
      })
    },
    chargeCustomerCancelFee({ commit }, appointmentId) {
      commit('SAVE_CHARGE_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.post(sprintf.sprintf(`${APPOINTMENT_PAYMENT_CANCEL_FEE_API_ENDPOINT}`, { appointmentId }))
          .then(response => {
            commit('SAVE_CHARGE_CUSTOMER_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('SAVE_CHARGE_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
    chargeCustomerNoShowFee({ commit }, appointmentId) {
      commit('SAVE_CHARGE_CUSTOMER_PENDING')
      return new Promise((resolve, reject) => {
        api.post(sprintf.sprintf(`${APPOINTMENT_PAYMENT_NO_SHOW_FEE_API_ENDPOINT}`, { appointmentId }))
          .then(response => {
            commit('SAVE_CHARGE_CUSTOMER_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('SAVE_CHARGE_CUSTOMER_ERROR', error)
            reject(error)
          })
      })
    },
    getCustomerFeeAvailability({ commit }, { appointmentId, type }) {
      commit('FETCH_CUSTOMER_FEE_AVAILABILITY_PENDING')
      return new Promise((resolve, reject) => {
        api.get(sprintf.sprintf(`${APPOINTMENT_PAYMENT_FEE_AVAILABILITY_API_ENDPOINT}`, { appointmentId, type }))
          .then(response => {
            commit('FETCH_CUSTOMER_FEE_AVAILABILITY_RECEIVED', response)
            resolve(response)
          })
          .catch(error => {
            commit('FETCH_CUSTOMER_FEE_AVAILABILITY_ERROR', error)
            reject(error)
          })
      })
    },
    saveSelectedPeriod({ commit }, period) {
      commit('SAVE_SELECTED_PERIOD', period)
    },
    showAppointmentContextMenuModal({ commit }, appointmentDetails) {
      commit('SHOW_APPOINTMENT_CONTEXT_MENU_MODAL', appointmentDetails)
    },
    hideAppointmentContextMenuModal({ commit }) {
      commit('HIDE_APPOINTMENT_CONTEXT_MENU_MODAL')
    },
    showAppointmentCheckoutModal({ commit }) {
      commit('SHOW_APPOINTMENT_CHECKOUT_MODAL')
    },
    hideAppointmentCheckoutModal({ commit }) {
      commit('HIDE_APPOINTMENT_CHECKOUT_MODAL')
    },
    showAppointmentModal({ commit }, appointmentDetailsModal) {
      commit('SHOW_APPOINTMENT_MODAL', appointmentDetailsModal)
    },
    hideAppointmentModal({ commit }) {
      commit('HIDE_APPOINTMENT_MODAL')
    },
    showAppointmentStatusExtraConfirmToChargeModal({ commit }, appointmentStatus) {
      commit('SHOW_APPOINTMENT_STATUS_EXTRA_CONFIRM_TO_CHARGE_MODAL', appointmentStatus)
    },
    hideAppointmentStatusExtraConfirmToChargeModal({ commit }) {
      commit('HIDE_APPOINTMENT_STATUS_EXTRA_CONFIRM_TO_CHARGE_MODAL')
    },
    clearAppointmentDelete({ commit }) {
      commit('CLEAR_APPOINTMENT_DELETE')
    },
    fetchStylist({ commit }, stylistId) {
      commit('FETCH_STYLIST_PENDING')
      return new Promise(() => {
        api.get(`/api/stylists/${stylistId}`)
          .then(response => {
            commit('FETCH_STYLIST_RECEIVED', response)
          })
          .catch(error => {
            commit('FETCH_STYLIST_ERROR', error)
          })
      })
    },
    changeAppointmentState(context, { appointmentId, transition }) {
      return new Promise((resolve, reject) => {
        api.post(`/api/appointment/${appointmentId}/set-state`, {
          transition,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAppointmentTransitions({ commit }, appointmentId) {
      commit('FETCH_APPOINTMENT_TRANSITIONS_PENDING', appointmentId)
      return new Promise((resolve, reject) => {
        api.get(`/api/appointment/${appointmentId}/transitions`)
          .then(response => {
            commit('FETCH_APPOINTMENT_TRANSITIONS_RECEIVED', {
              response,
              appointmentId,
            })
            resolve(response.data)
          })
          .catch(error => {
            commit('FETCH_APPOINTMENT_TRANSITIONS_ERROR', {
              error,
              appointmentId,
            })
            reject(error)
          })
      })
    },
  },
}
