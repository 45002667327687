export default [
  {
    path: '/',
    name: 'landing-page',
    component: () => import('@/components/landing/LandingPage.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'login-redirect',
    component: () => import('@/components/landing/LandingPageRedirect.vue'),
    meta: {
      layout: 'full',
    },
  },
]
