<template>
  <b-overlay
    :show="loading"
    variant="secondary"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="lg"
  >
    <b-card-code
      title="Notification channels"
      card-header-class="pb-1"
    >
      <p class="pb-1 mb-0">
        Select channels through which your customers will receive notifications related to appointments
      </p>
      <div>
        <validation-observer
          ref="notificationForm"
        >

          <b-form
            class="mt-0 notifications"
            @submit.prevent="submitForm"
          >
            <b-row class="pt-1">
              <b-col cols="12">
                <b-form-checkbox
                  v-model="localData.emailNotifications"
                  name="check-button"
                  switch
                  inline
                >
                  Email notifications
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-checkbox
                  v-model="localData.smsNotifications"
                  name="check-button"
                  switch
                  inline
                >
                  TEXT notifications
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-checkbox
                  v-model="localData.reviewRequestNotifications"
                  name="check-button"
                  switch
                  inline
                >
                  Review request notifications
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mt-1"
              >
                <h4>Appointment reminders</h4>
                <p>Define if and how much time before appoitnment your customers should receive a reminder</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-checkbox
                  v-model="localData.appointmentReminder"
                  name="check-button"
                  switch
                  inline
                >
                  Appointment reminders
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="pb-0 mt-1">
              <b-col cols="12">
                <p class="mb-0">
                  Deliver appointment reminder this many hours before scheduled time
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <validation-provider
                  #default="{ valid, errors }"
                  name="Reminder"
                  rules="integer"
                >
                  <b-form-group>
                    <b-input-group
                      append="Hrs"
                    >
                      <b-form-input
                        v-model.number="localData.appointmentReminderHours"
                        type="number"
                        :disabled="localData.appointmentReminder != true"
                        placeholder="0"
                        min="1"
                        max="48"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="pb-0">
              <b-col
                cols="12"
                class="mt-1"
              >
                <h4>Custom text</h4>
                <p class="mb-0">
                  Use the field below if you wish to add some extra information to the messages sent to your customers (i.e. floor number, bell name, parking details etc.)
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <b-form-input
                    v-model="localData.notificationsText"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="pl-0 mt-1"
              >
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    type="submit"
                    :disabled="saving"
                  >
                    Save changes
                  </b-button>
                  <div
                    v-if="saving"
                    class="mb-1 d-inline"
                  >
                    <b-spinner small />
                  </div>
                </b-col>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </div>
    </b-card-code>
  </b-overlay>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import Vuelayers, {
  TileLayer,
  OsmSource,
  Overlay,
} from 'vuelayers'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import BCardCode from '@core/components/b-card-code'
import {
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vuelayers(Vue)

export default {
  components: {
    BCardCode,
    BSpinner,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    TileLayer,
    // eslint-disable-next-line vue/no-unused-components
    OsmSource,
    // eslint-disable-next-line vue/no-unused-components
    Overlay,
    // validations -->
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localData: {},
      zoom: 2,
      center: [0, 0],
      rotation: 0,
      targetPosition: false,
      showLatLong: false,
      showMap: false,
      timeZonesDictionary: [],
      timeZone: null,
    }
  },
  computed: {
    ...mapState('AccountData', ['stylistData', 'loaded', 'loading', 'saving']),
  },
  watch: {
    currentTab() {
      this.$refs.vlMap.mount()
    },
    stylistData: {
      deep: true,
      handler(newStylistData) {
        const timeZoneId = newStylistData.timeZone ? newStylistData.timeZone.id : null
        this.localData = { ...newStylistData, timeZone: timeZoneId }
      },
    },
  },
  methods: {
    ...mapActions('AccountData', ['saveStylistData']),
    ...mapMutations('AccountData', ['unsetSaving']),
    async saveChanges() {
      try {
        await this.saveStylistData(this.localData)
        this.localData = { ...this.stylistData }
        if (typeof this.stylistData.timeZone !== 'undefined') {
          this.localData.timeZone = this.stylistData.timeZone.id
        }
        this.success()
      } catch (e) {
        this.fail()
      }
    },

    async submitForm() {
      const validated = await this.$refs.notificationForm.validate()

      if (!validated) {
        return
      }
      await this.saveChanges()
    },

    success() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Stylist settings had been updated',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },

    fail() {
      this.unsetSaving()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Stylist settings update failed',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
  height: 100% !important;
}
.overlay-content {
  border-radius: 100%;
  border: 2px solid red;
  background-color: white;
  min-width: 12px;
  min-height: 12px;
  display: block;
  transform: translateX(-6px) translateY(-6px);
}
.card .card-header p{
  padding-bottom: 1.5rem;
}
form.notifications {
  .row {
    padding-bottom: 1.5rem;
  }
  h4 {
    margin-bottom: 1.0rem;
  }
}
</style>
