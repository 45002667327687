<template>
  <b-tabs
    v-model="activeTab"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- account settings tab -->
    <b-tab @click="setActiveTab(tabs.ACCOUNT_DETAILS)">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Account details</span>
      </template>

      <stylist-setting-account />
    </b-tab>
    <!--/ account settings tab -->

    <!-- open hour tab -->
    <b-tab
      @click="setActiveTab(tabs.OPEN_HOURS)"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Open hours</span>
      </template>

      <stylist-setting-open-hour />
    </b-tab>

    <!-- payment acceptance tab -->
    <b-tab
      :active="$route.query.activeTab == 'payment'"
      @click="setActiveTab(tabs.ACCEPTING_PAYMENTS)"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Payment acceptance</span>
      </template>

      <stylist-setting-payment />
    </b-tab>
    <!--/ payment acceptance tab -->

    <!-- policies tab -->
    <b-tab
      :active="$route.query.activeTab == 'policy'"
      @click="setActiveTab(tabs.NO_SHOW_PROTECTION)"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">No-show Protection</span>
      </template>
      <stylist-setting-no-show-protection />
    </b-tab>
    <!--/ policies tab -->
    <!-- messages tab -->
    <b-tab
      @click="setActiveTab(tabs.MESSAGES_NOTIFICATIONS)"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="MessageCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Messages & Notifications</span>
      </template>

      <stylist-setting-messages-notifications />
    </b-tab>
    <!--/ messages tab -->

    <!-- subscription tab -->
    <b-tab
      @click="setActiveTab(tabs.SUBSCRIPTION)"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Subscription</span>
      </template>

      <stylist-setting-subscription />
    </b-tab>
    <!--/ subscription tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import StylistSettingNoShowProtection from '@/components/stylist/stylist-setting/StylistSettingNoShowProtection.vue'
import { mapActions, mapState } from 'vuex'
import StylistSettingTabsEnum from '@/dictionaries/stylistSettingTabsEnum'
import StylistSettingOpenHour from './StylistSettingOpenHour.vue'
import StylistSettingAccount from './StylistSettingAccountDetails.vue'
import StylistSettingMessagesNotifications from './StylistSettingMessagesNotifications.vue'
import StylistSettingPayment from './StylistSettingPayment.vue'
import StylistSettingSubscription from './StylistSettingSubscription.vue'

export default {
  components: {
    BTabs,
    BTab,
    StylistSettingOpenHour,
    StylistSettingAccount,
    StylistSettingNoShowProtection,
    StylistSettingMessagesNotifications,
    StylistSettingPayment,
    StylistSettingSubscription,
  },
  data() {
    return {
      options: {},
      activeTab: 0,
      tabs: { ...StylistSettingTabsEnum },
    }
  },
  computed: {
    ...mapState('StylistSettingStoreModule', {
      activeTabStore: state => state.activeTab,
    }),
  },
  watch: {
    activeTabStore: {
      deep: true,
      handler(newActiveTab) {
        this.activeTab = newActiveTab
      },
    },
  },
  mounted() {
    this.fetchStylistData()
  },
  methods: {
    ...mapActions('StylistSettingStoreModule', [
      'setActiveTab',
    ]),
    ...mapActions('AccountData', ['fetchStylistData']),
  },
}
</script>
