import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { parseDomain, ParseResultType } from 'parse-domain'
import {
  AUTH_LOGIN_PATH, AUTH_REGISTER_PATH, APPS_CALENDAR, COMMON_ERROR_403, AUTH_CUSTOMER_CALLBACK_PATH, SUBSCRIPTION_PLANS_PATH,
} from '@/router/routes/routes-path'
import { ERROR_404, AUTH_LOGOUT } from '@/router/routes/routes-names'
import common from '@/router/routes/common'
import { ROLE_CUSTOMER, ROLE_STYLIST } from '@/auth/userRolesDictionary'
import apps from './routes/apps'
import auth from './routes/auth'
import landing from './routes/landing'

Vue.use(VueRouter)
const parseDomainResult = parseDomain(window.location.hostname)
const currentPort = typeof window.location.port !== 'undefined' ? `:${window.location.port}` : null

let currentDomain = null
let currentSubdomain = null

if (parseDomainResult.type === ParseResultType.Listed) {
  const { subDomains, domain, topLevelDomains } = parseDomainResult
  currentDomain = `${domain}.${topLevelDomains}${currentPort}`
  currentSubdomain = typeof subDomains[0] !== 'undefined' && subDomains[0] !== 'www' ? subDomains[0] : null
}
let routePath = currentSubdomain ? apps : landing
if (currentSubdomain === 'app') {
  routePath = auth
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...routePath,
    ...common,
    {
      path: '*',
      redirect: ERROR_404,
    },
  ],
})

router.beforeEach((to, _, next) => {
  const {
    isLogged, isRegistered, isSubscribed, subdomain, roles,
  } = Vue.prototype.$auth

  const rolesArray = roles || 'LOGGED_IN'
  const requireRoles = to.meta.requireRoles ? to.meta.requireRoles : []

  const hasRole = role => rolesArray.includes(role)

  if (isLogged && !isRegistered && requireRoles.includes(ROLE_STYLIST) && rolesArray.includes(ROLE_STYLIST)) {
    if (to.name !== 'auth-register') {
      window.location.href = `https://${currentDomain}${AUTH_REGISTER_PATH}`
      return false
    }
    return next()
  }

  // Redirect if not logged in
  if (to.meta.requireLogin
      && requireRoles.includes(ROLE_STYLIST)
      && !isLogged) {
    window.location.href = `https://${currentDomain}${AUTH_LOGIN_PATH}`
    return false
  }

  // Redirect if not logged in
  if (to.meta.requireLogin
      && requireRoles.includes(ROLE_CUSTOMER)
      && !isLogged) {
    window.sessionStorage.setItem('returnTo', JSON.stringify({
      path: to.path,
    }))

    Vue.prototype.$authCustomer.loginWithRedirect({
      redirect_uri: `${window.location.origin}${AUTH_CUSTOMER_CALLBACK_PATH}`,
    })
    return false
  }

  // Redirect if user changes subdomain
  if (to.meta.requireLogin
      && isLogged
      && requireRoles.includes(ROLE_STYLIST)
      && requireRoles.some(hasRole)
      && currentSubdomain !== null
      && subdomain !== currentSubdomain) {
    window.location.href = `https://${subdomain}.${currentDomain}${to.path}`
    return false
  }

  if (to.meta.requireRoles) {
    // Redirect not permission
    if (to.meta.requireLogin
        && to.name !== AUTH_LOGOUT
        && !requireRoles.some(hasRole)) {
      window.location.href = `https://${currentDomain}${COMMON_ERROR_403}`
      return false
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn
      && requireRoles.includes(ROLE_STYLIST)
      && requireRoles.some(hasRole)) {
    let url = `https://${subdomain}.${currentDomain}`
    if (+currentPort !== 80) {
      url += `${currentPort}`
    }
    window.location.href = `${url}${APPS_CALENDAR}`
    return false
  }

  if (to.meta.requireSubscription
    && !isSubscribed) {
    window.location.href = `https://${subdomain}.${currentDomain}${SUBSCRIPTION_PLANS_PATH}`
    return false
  }

  return next()
})

export default router
