import {
  SUBSCRIPTION_STYLIST_LIST,
  SUBSCRIPTION_STYLIST_CHECKOUT,
  SUBSCRIPTION_STYLIST_STATUS,
  SUBSCRIPTION_STYLIST_START_TRIAL,
  SUBSCRIPTION_STYLIST_USE_PROMO_CODE, SUBSCRIPTION_STYLIST_APPLY_PROMO_CODE,
} from '@/api/apiUrl'
import { apiInstance as api } from '@/api/plugin'

export default {
  namespaced: true,
  state: {
    verified: {
      success: null,
      pending: false,
      error: null,
    },
    subscription: {
      response: null,
      pending: false,
      error: null,
    },
    planData: {
      response: {
        type: null,
        product: null,
        prices: [],
      },
      pending: false,
      error: null,
    },
    checkout: {
      response: null,
      pending: false,
      error: null,
    },
    promoCode: {
      code: null,
      response: null,
      pending: false,
      error: null,
      applied: false,
    },
  },
  mutations: {
    FETCH_PLANS_PENDING(state) {
      state.planData.response.type = null
      state.planData.response.product = null
      state.planData.response.prices = []
      state.planData.pending = true
      state.planData.error = null
    },
    FETCH_PLANS_RECEIVED(state, response) {
      const { prices, product, type } = response.data
      state.planData.response.type = type
      state.planData.response.prices = prices
      state.planData.response.product = product
      state.planData.pending = false
      state.planData.error = null
    },
    FETCH_PLANS_ERROR(state, error) {
      state.planData.response.type = null
      state.planData.response.product = null
      state.planData.response.prices = []
      state.planData.pending = false
      state.planData.error = error
    },
    CHECKOUT_STYLIST_PLAN_PENDING(state) {
      state.checkout.response = null
      state.checkout.pending = true
      state.checkout.error = null
    },
    CHECKOUT_STYLIST_PLAN_RECEIVED(state, response) {
      state.checkout.response = response.data
      state.checkout.pending = true
      state.checkout.error = null
    },
    CHECKOUT_STYLIST_PLAN_ERROR(state, error) {
      state.checkout.response = null
      state.checkout.pending = true
      state.checkout.error = error
    },
    CHECK_STYLIST_SUBSCRIPTION_PENDING(state) {
      state.subscription.response = null
      state.subscription.pending = true
      state.subscription.error = null
    },
    CHECK_STYLIST_SUBSCRIPTION_RECEIVED(state, response) {
      state.subscription.response = response.data.subscription
      state.subscription.pending = false
      state.subscription.error = null
    },
    CHECK_STYLIST_SUBSCRIPTION_ERROR(state, error) {
      state.subscription.response = null
      state.subscription.pending = false
      state.subscription.error = error
    },
    VERIFY_STYLIST_SUBSCRIPTION_PENDING(state) {
      state.verified.success = null
      state.verified.pending = true
      state.verified.error = null
    },
    VERIFY_STYLIST_SUBSCRIPTION_RECEIVED(state, success) {
      state.verified.success = success
      state.verified.pending = false
      state.verified.error = null
    },
    VERIFY_STYLIST_SUBSCRIPTION_ERROR(state, error) {
      state.verified.success = null
      state.verified.pending = false
      state.verified.error = error
    },
    START_STYLIST_TRIAL_PENDING(state) {
      state.subscription.success = null
      state.subscription.pending = true
      state.subscription.error = null
    },
    START_STYLIST_TRIAL_RECEIVED(state, success) {
      state.subscription.success = success
      state.subscription.pending = false
      state.subscription.error = null
    },
    START_STYLIST_TRIAL_ERROR(state, error) {
      state.subscription.success = null
      state.subscription.pending = false
      state.subscription.error = error
    },
    USE_STYLIST_PROMO_CODE_PENDING(state, code) {
      state.promoCode.code = code
      state.promoCode.response = null
      state.promoCode.pending = true
      state.promoCode.error = null
    },
    USE_STYLIST_PROMO_CODE_RECEIVED(state, response) {
      state.promoCode.response = response.data
      state.promoCode.pending = false
      state.promoCode.error = null
    },
    USE_STYLIST_PROMO_CODE_ERROR(state, error) {
      state.promoCode.code = null
      state.promoCode.response = null
      state.promoCode.pending = false
      state.promoCode.error = error
    },
    APPLY_STYLIST_PROMO_CODE_PENDING(state) {
      state.promoCode.applied = false
      state.promoCode.pending = true
      state.promoCode.error = null
    },
    APPLY_STYLIST_PROMO_CODE_SUCCESS(state) {
      state.promoCode.applied = true
      state.promoCode.pending = false
      state.promoCode.error = null
    },
    APPLY_STYLIST_PROMO_CODE_ERROR(state, error) {
      state.promoCode.applied = false
      state.promoCode.pending = false
      state.promoCode.error = error
    },
  },
  actions: {
    fetchStylistPlans({ commit }) {
      commit('FETCH_PLANS_PENDING')
      return new Promise((resolve, reject) => {
        api.get(SUBSCRIPTION_STYLIST_LIST)
          .then(response => {
            commit('FETCH_PLANS_RECEIVED', response)
            resolve(response)
          })
          .catch(err => {
            commit('FETCH_PLANS_ERROR', err)
            reject(err)
          })
      })
    },
    checkoutStylistPlan({ commit }, { priceId, promoCode, returnUrl }) {
      commit('CHECKOUT_STYLIST_PLAN_PENDING')
      return new Promise((resolve, reject) => {
        api.post(SUBSCRIPTION_STYLIST_CHECKOUT, { priceId, promoCode, returnUrl })
          .then(response => {
            commit('CHECKOUT_STYLIST_PLAN_RECEIVED', response)
            resolve(response)
          })
          .catch(err => {
            commit('CHECKOUT_STYLIST_PLAN_ERROR', err)
            reject(err)
          })
      })
    },
    checkStylistSubscriptionStatus({ commit }) {
      commit('CHECK_STYLIST_SUBSCRIPTION_PENDING')
      return new Promise((resolve, reject) => {
        api.get(SUBSCRIPTION_STYLIST_STATUS)
          .then(response => {
            commit('CHECK_STYLIST_SUBSCRIPTION_RECEIVED', response)
            resolve(response)
          })
          .catch(err => {
            commit('CHECK_STYLIST_SUBSCRIPTION_ERROR', err)
            reject(err)
          })
      })
    },
    startStylistTrial({ commit }, priceId) {
      commit('START_STYLIST_TRIAL_PENDING')
      return new Promise((resolve, reject) => {
        api.post(SUBSCRIPTION_STYLIST_START_TRIAL, { priceId })
          .then(response => {
            commit('START_STYLIST_TRIAL_RECEIVED', response)
            resolve(response)
          })
          .catch(err => {
            commit('START_STYLIST_TRIAL_ERROR', err)
            reject(err)
          })
      })
    },
    useStylistPromoCode({ commit }, promoCode) {
      commit('USE_STYLIST_PROMO_CODE_PENDING', promoCode)
      return new Promise((resolve, reject) => {
        api.post(SUBSCRIPTION_STYLIST_USE_PROMO_CODE, { promoCode })
          .then(response => {
            commit('USE_STYLIST_PROMO_CODE_RECEIVED', response)
            resolve(response)
          })
          .catch(err => {
            commit('USE_STYLIST_PROMO_CODE_ERROR', err)
            reject(err)
          })
      })
    },
    applyStylistPromoCode({ commit, state }) {
      commit('APPLY_STYLIST_PROMO_CODE_PENDING')
      return new Promise((resolve, reject) => {
        api.post(SUBSCRIPTION_STYLIST_APPLY_PROMO_CODE, { promoCode: state.promoCode.code })
          .then(response => {
            commit('APPLY_STYLIST_PROMO_CODE_SUCCESS')
            resolve(response)
          })
          .catch(err => {
            commit('APPLY_STYLIST_PROMO_CODE_ERROR', err)
            reject(err)
          })
      })
    },
  },
}
